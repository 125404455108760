@use 'variables' as var;
@use 'mixins';

// font family
.ff-monts {
  font-family: 'Montserrat', sans-serif;
}
.ff-sf_pro {
  font-family: 'SF-Pro', sans-serif;
}
.ff-gilroy {
  font-family: 'Gilroy', sans-serif;
}

// font weight
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}

// text aligh
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-width {
  text-align: justify;
}

// font size
h1, .h1 {
  font-size: 2.25rem; // 36px
}
h2, .h2 {
  font-size: 1.625rem; // 26px
  line-height: 1.192; //31px
  letter-spacing: var.$lSpacing;
}
h3, .h3 {
  font-size: 1.5rem; //24px
}
h4, .h4 {
  font-size: 1.375rem; // 22px
  line-height: 1.27; //28px
  color: var.$black;
  letter-spacing: var.$lSpacing;
}
h5, .h5 {
  font-size: 1.125rem; // 18px
  line-height: 1.22; // 22px
  font-weight: 600;
  letter-spacing: var.$lSpacing_xs;
}
p, .p {
  font-size: 1rem;
  line-height: 1.375;
}

.small-text {
  font-size: mixins.rem(13); // 13px
  line-height: 1.3;
}

.text_overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fs-12 {
  font-size: 0.75rem; // 12px
  line-height: 1.25;
}
.fs-14 {
  font-size: 0.875rem; // 14px
  line-height: 1.57;
}
.fs-18 {
  font-size: 1.125rem; // 18px
}
.fs-16 {
  font-size: 1rem; // 16px
}

.fs-32 {
  font-size: 2rem; // 32px
}

// br
.br {
  display: block;
}


//== spacing ==//

.l-spacing {
  letter-spacing: 0.8px;
}
.l-spacing_xs {
  letter-spacing: -0.18px;
}

.w-spacing {
  word-spacing: 5px;
}


.text-underline {
  text-decoration: underline transparent;
  transition: var.$transitionGlobal;
  -webkit-text-decoration-line:  underline !important;
  -webkit-text-decoration-color: transparent !important;
  -webkit-text-decoration-style: solid !important;
  -webkit-text-decoration-thickness: 1px !important;

  @include mixins.hover-supported {
    text-decoration-color: inherit;
    -webkit-text-decoration-color: inherit !important;
  }
}
