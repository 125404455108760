@use '../../../assets/sass/variables';
@use '../../../assets/sass/common-elements/buttons';
.delegate {
  .container {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &__user {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto;
    column-gap: 15px;
    grid-template-areas:
      'icon name'
      'icon text';
  }

  &__ava {
    grid-area: icon;
    width: 64px !important;
    height: 64px !important;
    border: 1px solid variables.$green;
  }
  &__name {
    grid-area: name;
    align-self: end;
  }
  &__text {
    grid-area: text;
    color: variables.$gray_dark_2;
  }

  &__btn {
    @extend .btn__outline;
    &[disabled] {
      @extend .btn__disabled;
    }
  }

  label {
    min-height: auto;
  }

  &__delete {
    display: flex;
    max-width: max-content;
    margin-left: auto;
    margin-top: auto;
    padding: 10px 0;
    background: transparent;
    color: variables.$orange;
    font: 400 1.125rem/1 'Gilroy', sans-serif;
  }
}

.card_section {
  display: flex;
  flex-direction: column;
  padding: 15px;

  &__link {
    display: grid;
    grid-template-columns: 48px 1fr auto;
    grid-auto-rows: auto;
    column-gap: 15px;
    align-items: center;
    justify-content: start;

    &__label {
      grid-template-columns: 48px 1fr;

      label {
        grid-row: 2/3;
        span:nth-child(2) {
          font: 600 1.125rem/1 'Gilroy', sans-serif !important;
          color: variables.$black;
        }
      }
    }

    &:not(:last-child) {
      padding-bottom: 5px;
    }

    &:not(:first-child) {
      &::after {
        grid-column: 2/-1;
        grid-row: 1/2;
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background: variables.$gray_light;
        margin-bottom: 5px;
      }
    }

    &__soon {
      pointer-events: none;
      user-select: none;
    }
  }

  &__icon {
    grid-row: 2/3;
    background: variables.$orange_light_2;
    padding: 12px;
    border-radius: variables.$radius_s;

    &__soon {
      opacity: 0.4;
    }
  }

  &__btn {
    justify-self: start;
    grid-row: 2/3;
    font: 600 1.125rem/1.3 'Gilroy', sans-serif;
    color: variables.$black;
    background: transparent;
    text-align: left;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &__soon {
      opacity: 0.4;
    }
  }

  &__arrow {
    grid-row: 2/3;
  }
}
