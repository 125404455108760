@use '../../../assets/sass/variables' as var;
@use '../../../assets/sass/mixins';

.tol-tabs-container {
  position: relative;
}

.tol-tabs {
  display: flex;
  height: 40px;
  box-shadow: 0px 4px 6px -2px rgba(88, 88, 88, 0.1), 0px 2px 10px 0px rgba(88, 88, 88, 0.1);
  border: 1px solid var.$white_dark;
  border-radius: 8px;
}

.tol-tab {
  font-size: 0.875rem;
  font-weight: 400;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var.$black;
  position: relative;
  z-index: 1;
  transition: color 0.2s;

  &.is-active {
    font-size: 1rem;
    font-weight: 600;
    color: white;
  }
}

.tol-tabs-slider {
  position: absolute;
  inset: 0;
  padding: 2px;
}

.tol-tabs-slider-rect {
  height: 36px;
  width: 50%;
  background-image: radial-gradient(
                    96.23% 85.68% at 14.33% 14.32%,
                    #ce1ff6 0%,
                    #575fee 100%);
  border-radius: 6px;
  transform: translateX(0);
  transition: transform 0.2s;
}