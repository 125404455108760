@use './variables' as *;

.bg-white {
  background-color: $white;
}
.bg-white_dark {
  background-color: $white_dark;
}
.bg-black {
  background-color: $black;
}
.bg-gray {
  background-color: $gray;
}
.bg-gray_light {
  background-color: $gray_light;
}
.bg-gray_text {
  background-color: $gray_text;
}
.bg-gray_tex-light {
  background-color: $gray_text-light;
}
.bg-gray_border {
  background-color: $gray_border;
}
.bg-gray_dark {
  background-color: $gray_dark;
}
.bg-gray_dark_2 {
  background-color: $gray_dark_2;
}
.bg-violet {
  background-color: $violet;
}
.bg-violet_light {
  background-color: $violet_light;
}
.bg-green {
  background-color: $green;
}
.bg-red {
  background-color: $red;
}
.bg-red_2 {
  background-color: $red_2;
}
.bg-orange {
  background-color: $orange;
}
.bg-orange_light {
  background-color: $orange_light;
}

.text-white_dark {
  color: $white_dark;
}
.text-white {
  color: $white;
}
.text-black {
  color: $black;
}
.text-gray {
  color: $gray;
}
.text-gray_light {
  color: $gray_light;
}
.text-gray_text {
  color: $gray_text;
}
.text-gray_text-light {
  color: $gray_text-light;
}
.text-gray_border {
  color: $gray_border;
}
.text-gray_dark {
  color: $gray_dark;
}
.text-gray_dark_2 {
  color: $gray_dark_2;
}
.text-violet {
  color: $violet;
}
.text-violet_light {
  color: $violet_light;
}
.text-green {
  color: $green;
}
.text-success {
  color: $success;
}
.text-red {
  color: $red;
}
.text-red_2 {
  color: $red_2;
}
.text-error {
  color: $error;
}
.text-orange {
  color: $orange;
}
.text-orange_light {
  color: $orange_light;
}

.premium-color {
  background: radial-gradient(
    96.23% 85.68% at 14.33% 14.32%,
    #ce1ff6 0%,
    #575fee 100%
  );
  background-clip: text;
  color: transparent;
}
.premium-bg {
  background: radial-gradient(
    96.23% 85.68% at 14.33% 14.32%,
    #ce1ff6 0%,
    #575fee 100%
  );
}
.premium-bg--lite {
  background: radial-gradient(
      96.23% 85.68% at 14.33% 14.32%,
      rgba(206, 31, 246, 0.14) 0%,
      rgba(87, 95, 238, 0.14) 100%
    ),
    $white;
}
