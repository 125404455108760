@use '../../../assets/sass/variables' as var;
@use '../../../assets/sass/mixins';

.avatar {
  &__logo {
    max-width: 114px;
    margin-bottom: clamp(20px, 7vh, 50px);
  }
  &__content {
    margin-bottom: clamp(35px, 6vw, 48px);
    display: grid;
    grid-template-columns: auto 1fr 23px;
    align-items: center;
    width: 100%;
    padding-inline: 4px;
  }
  .avatar__link {
    grid-column: 1 / -1;
  }
  .avatar__name {
    margin-top: 0;
    font-weight: 600;
    font-size: mixins.rem(18);
    letter-spacing: -0.18px;
    word-break: break-word;
  }
  .avatar__switch {
    //margin-top: 12px;
    .active {
      filter: contrast(1);
    }
    @include mixins.hover-supported {
      img {
        filter: contrast(1);
      }
    }
    &:not(.active, :hover) img {
      filter: contrast(0) brightness(140%);
      transition: .3s;
    }
    img {
      transition: var.$transitionGlobal;
    }
  }
  .avatar__photo + div {
    margin-top: 0;
  }


  // min width 768
  @include mixins.min-width(var.$tablet-res) {
    &__content {
      padding-inline: 15px;
      align-items: center;
      grid-gap: 8px;
    }
    &__name {
      color: white;
    }
    .avatar__photo  {
      width: 40px;
      height: 40px;
    }

  }
  // max width 767.9
  @include mixins.max-width(var.$mobile-res) {
    &__content {
      grid-gap: 12px;
    }
    .avatar__photo {
      width: 48px;
      height: 48px;
    }

  }
}
