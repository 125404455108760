@use '../../../assets/sass/variables' as var;
@use '../../../assets/sass/mixins';
@use '../../../assets/sass/common-elements/buttons';
@use '../../../assets/sass/common-elements/cards';
@use '../../../assets/sass/common-elements/forms';

.details {
  .container {
    height: 100%;
  }
  &__wrapper {
    @include mixins.d-flex(false, false, column);
    flex: 1 1;
    @include mixins.min-width(var.$tablet-res) {
      max-width: 546px;
      width: 100%;
      align-self: center;
    }
  }
  &__user {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto;
    column-gap: 15px;
    grid-template-areas:
      'icon name'
      'icon text';
    @include mixins.adaptiv-value("margin-bottom", 30, 20, 1);
  }

  &__ava {
    grid-area: icon;
    width: 64px !important;
    height: 64px !important;
    border: 1px solid var.$orange;
  }
  &__name {
    grid-area: name;
    align-self: end;
  }
  &__text {
    grid-area: text;
    color: var.$gray_dark_2;
  }

  label {
    min-height: auto;
  }

  .card_section {
    display: flex;
    flex-direction: column;
    padding: 15px;
    margin-bottom: 20px;

    &__link {
      display: grid;
      grid-auto-rows: auto;
      column-gap: 15px;
      align-items: center;
      justify-content: start;
      &:not( &__soon) {
        grid-template-columns: 48px 1fr;
      }
      &:not(&__label, &__soon) {
        column-gap: 5px;
        position: relative;
        .card_section__title {

          border-radius: var.$radius_s;
          transition: var.$transitionGlobal;
        }
        @include mixins.hover-supported {
          &:before {
            filter: brightness(1);
          }
          .card_section__title {
            background-color: var.$hover_main;
          }
        }
        &:before {
          @include mixins.chevron(absolute, 9px, $colorBlack: true);
          transition: var.$transitionGlobal;
        }
      }
      &:not(:first-child) {
        &:before {
          top: 30px;
        }
        &::after {
          @include mixins.divider(1px, calc(100% - 10px), $color: var.$white_dark);
          position: static;
          margin: 6px 0 6px 10px;
          grid-column: 2/-1;
          grid-row: 1/2;
        }
      }
      .card_section__title {
        padding: 13px 10px;
      }
      &__label label {
        grid-row: 2/3;
        span:nth-child(2) {
          font: 600 1.125rem/1 'Gilroy', sans-serif !important;
          color: var.$black;
        }
      }

      &__soon {
        grid-template-columns: 48px 1fr auto;
        column-gap: 5px;
        pointer-events: none;
        user-select: none;
        .card_section__icon, .card_section__title {
          opacity: 0.4;
        }
      }
    }

    &__icon {
      grid-row: 2/3;
      background: var.$orange_light_2;
      padding: 12px;
      border-radius: var.$radius_xs;
    }

    &__title {
      justify-self: start;
      grid-row: 2/3;
      font: 600 1.125rem/1.3 'Gilroy', sans-serif;
      color: var.$black;
      background: transparent;
      text-align: left;
      width: 100%;
    }

    &__arrow {
      grid-row: 2/2;
    }
  }
}
