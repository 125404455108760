@use '../../../assets/sass/variables' as *;
@use '../../../assets/sass/mixins' as *;

/* media */
@include min-width($tablet-res) {
  .acc_edit__wrapper {
    padding: 30px 30px 40px;
    max-width: 546px;
    > div {
      max-width: 315px;
      width: 100%;
      margin-inline: auto;
    }
  }
}
@include max-width($mobile-res) {
  .container {
    height: 100%;
  }
  .acc_edit__wrapper {
    @include d-flex($direction: column);
    flex: 1;
  }
}
