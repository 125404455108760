@use '../variables' as var;
@use "../mixins" as mixin;
@use '../../../assets/sass/common-elements/buttons';

/*
  1. preview home_cards and guest page home_cards
  2. Olink cards at home page, add olink page
  3. Olink cards when edit
  4. desktop cards on settings pages
  5. repeatable styles for desktop blocks on edit, create olink pages
*/


.card {
  background-color: var.$white;
  border-radius: var.$radius_s;
  box-shadow:
          0 2px 10px rgba(88, 88, 88, 0.1),
          0 4px 6px -2px rgba(88, 88, 88, 0.1);
  &:not(:last-of-type, .setting_card) {
    margin-bottom: 20px;
  }
  &--xl {
    padding: 20px 30px 30px;
  }
  &--md {
    padding: 20px 24px 30px;
  }
  &--sm {
    padding: 20px 15px 30px;
  }
}
.home_cards {
  @include mixin.min-width(var.$tablet-res) {
    max-width: 650px;
    width: 100%;
    margin-inline: auto;
  }

  .setting_card:not(:last-of-type), > .list-group-item {
    margin-bottom: 20px;
  }
  .setting_card__icon {
    // default svg color
    @include mixin.d-flex(center, center);
    background-color: var.$white_dark;
    overflow: hidden;
    border-radius: 6px;
    svg {
      stroke: var.$white;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

// 1. styles for preview home_cards and guest page home_cards
.home.preview .home_cards, .home.guest_page .home_cards {
  .setting_card__top {
    min-height: 98px;
    border-bottom: 0;
    &:not(.setting_card__top__regular, .setting_card__top__pro) {
      padding: 14px;
    }
  }
  .setting_card__top__regular {
    padding: 16px 30px 16px;
    @include mixin.d-flex(
            $justifyContent: center,
            $direction: column
    );
  }
  .setting_card__top__pro {
    padding: 10px 10px 21px;
  }
  .setting_card__swap {
    transform: scale(0);
    opacity: 0;
    visibility: hidden;
    transition: 500ms ease;
  }
}

// 2. cards at home page, add olink page, events
.setting_card {
  @extend .card;
  position: relative;

  //hover for card btns
  &__edit,
  &__delete,
  .delete__no,
  .delete__yes,
  .setting_card__swap {
    @include mixin.hover-supported {
      &:after {
        opacity: 1;
      }
    }
  }

  //========== icons styles =========//
  &__swap {
    position: absolute;
    top: 10px;
    right: 10px;
    //background: transparent;
    padding-bottom: 20px;
    padding-left: 20px;
    cursor: grab;
    @include mixin.min-width(1201px) {
      &:after {
        @include mixin.bg-hover-element(44px, 44px);
        top: -10px;
        right: -10px;
        left: unset;
        transform: initial;
      }
      .icon-swap {
        position: relative;
        z-index: 1;
      }
    }
  }

  /* edit, delete buttons at bottom */
  &__edit,
  &__delete {
    position: relative;
    width: 64px;
    padding-block: 10px;
    font-size: 30px;
    border-left: 1px solid var.$white_dark;
    svg {
      display: block;
    }
    svg, img {
      margin-inline: auto;
    }
    /* media */
    @include mixin.min-width(var.$tablet-res) {
      &:after {
        @include mixin.bg-hover-element(43px, 43px)
      }
      svg, img {
        position: relative;
        z-index: 2;
      }
    }
  }


  &__checkbox {
    span {
      font-size: 0.813rem; // 13px
      line-height: 1.3;
      font-family: Gilroy, sans-serif;
      font-weight: 400;
    }
    span:first-child {
      color: var.$gray_dark_2;
    }
    span:last-child {
      color: var.$black;
    }

    &__on {
      span:first-child {
        color: var.$black;
      }
      span:last-child {
        color: var.$orange;
      }
    }
  }

  &__top {
    position: relative;
    min-height: 93px;
    border-bottom: 1px solid var.$white_dark;
    /* media */
    @include mixin.min-width(var.$tablet-res) {
      .link_save_home {
        margin-bottom: 10px;
      }

      &:not( .setting_card__top__regular, .setting_card__top__pro) {
        padding: 14px 14px 9px;
        display: grid;
        grid-template-columns: max-content 1fr;
        grid-template-rows: auto auto;
        grid-template-areas:
          'icon title'
          'icon text';
        column-gap: 15px;
        .setting_card__type {
          align-self: flex-end;
        }
        .setting_card__top__form {
          padding: 0 16px;
          grid-column: 1 / -1;
        }
      }
    }
    @include mixin.max-width(var.$mobile-res) {
      &:not(.setting_card__top__pro, .setting_card__top__regular) {
        padding: 14px 14px 9px;
        display: grid;
        grid-template-columns: max-content 1fr;
        grid-template-rows: auto auto;
        grid-template-areas:
                'icon title'
                'icon text';
        column-gap: 15px;
        .setting_card__type {
          align-self: flex-end;
        }
        .setting_card__top__form {
          padding: 0 16px;
          grid-column: 1 / -1;
        }
      }
      &:not(.setting_card__top__regular) {
        .link_save_home {
          margin-bottom: 10px;
        }
      }
    }

    /* pro link */
    &__pro {
      .setting_card__top__form {
        padding: 0 20px;
      }
      .img_input {
        border: 1px solid var.$white_dark;
      }

      /* media */
      //@include mixin.max-width(var.$mobile-res) {
        padding: 10px 10px 8px 10px;
        .setting_card__icon {
          width: 100%;
          height: 175px;
          object-fit: cover;
          object-position: center;
          border: none;
          margin-bottom: 19px;
        }
        .setting_card__type, .setting_card__text {
          text-align: center;
          padding: 0 20px;
        }
        .setting_card__swap {
          display: none;
        }
      //}
    }
    /* pro link empty cover  */
    .setting_card__empty_cover {
      background: radial-gradient(
                      96.23% 85.68% at 14.33% 14.32%,
                      #ce1ff6 0%,
                      #575fee 100%
      );
    }

    /* regular link */
    &__regular {
      @include mixin.min-width(var.$tablet-res) {
        @include mixin.d-flex(center, false, column);
        padding: 14px 30px 6px;
      }
      @include mixin.max-width(var.$mobile-res) {
        padding: 27px 30px 16px;
      }
    }
    &:not(.setting_card__top__regular, .setting_card__top__pro)
    .setting_card__top__form > *:first-child {
      @include mixin.adaptiv-value("margin-top", 17, 23, 1);
    }
    &__form {
      position: relative;
      z-index: 10;
    }
  }

  &__icon {
    width: 70px;
    height: 70px;
    grid-area: icon;
  }

  &__type {
    font-family: Gilroy, sans-serif;
    margin-bottom: 6px;
    grid-area: title;
  }

  &__text {
    color: var.$gray_dark_2;
    font: 400 0.875rem/1.5 'Montserrat', sans-serif;
    grid-area: text;
  }

  &__type, &__text {
    @include mixin.min-width(var.$tablet-res) {
      padding-right: 20px;
    }
  }

  &__bottom {
    display: grid;
    grid-template-columns: 1fr max-content max-content;
    padding: 6px 0 6px 27px;
    align-items: center;
    //cursor: initial;
    & > * {
      &:not(.setting_card__edit, .setting_card__delete) {
        padding: 3px 2px;
      }
      //position: relative;
    }
  }

  .delete {
    padding: 20px 15px 6px;
    &__state {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
    }
    &__no,
    &__yes {
      position: relative;
      font-size: 1rem;
      font-family: Gilroy, sans-serif;
      background: transparent;
      padding: 8px;
      z-index: 1;
      &:after {
        @include mixin.min-width(1201px) {
          @include mixin.bg-hover-element(77px, 34px)
        }
        z-index: -1;
      }
    }
    &__no {
      color: var.$gray_dark;
    }
    &__yes {
      border-left: 1px solid var.$gray_light;
      color: var.$error;
      @include mixin.hover-supported{
        &:after {
          background-color: var.$orange_light_2;
        }
      }
    }

  }
}

// 3. Olink cards when edit
.edit {
  &.setting_card__top__pro {
    padding-top: 20px;
  }
  &:not(.setting_card__top__pro) {
    @include mixin.min-width(var.$tablet-res) {
      padding-top: 24px;
    }
  }
  &:not(.setting_card__top__pro, .setting_card__top__regular) {
    @include mixin.max-width(var.$mobile-res) {
      padding-top: 27px;
    }
  }
  .setting_card__swap {
    display: none;
  }
  .setting_card__top__form {
    margin-top: 0;
  }
  a[class*='olink_url'] {
    display: none;
  }
}

// 4. styles for desktop cards on settings pages
.edit-card {
  @include mixin.min-width(var.$tablet-res) {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: var.$white;
    border-radius: var.$radius_s;
    box-shadow:
            0 2px 10px rgba(88, 88, 88, 0.1),
            0 4px 6px -2px rgba(88, 88, 88, 0.1);
  }
}

// 5. repeatable styles for blocks on edit, create olink pages
.edit-card-block {
  &__col1 .avatar{
    & > div {
      width: 105px;
      height: 105px;
    }
  }
  //for accessibility
  .add-photo-wrapper .MuiFormControl-root:focus-within +
  .edit-card-block__add-photo {
    text-decoration-color: inherit;
  }
  &__add-photo {
    font-size: mixin.rem(18);
    line-height: 1.2;
    cursor: pointer;
  }
  &__nameExample {
    padding-inline: 18px;
  }

  // min width 768
  @include mixin.min-width(var.$tablet-res) {
    @include mixin.d-flex(center, false, false, wrap);
    grid-gap: 30px 77px;
    max-width: 606px;
    padding: 30px 30px 40px;
    &__col1 {
      flex: 0 0 105px;
    }
    &__col2 {
      flex: 1 1 315px;
    }
    &__add-photo {
      margin-inline: -10px;
    }
    &__nameExample {
      margin-top: -12px;
      max-width: 363px;
    }
  }

  // max width 767.9px
  @include mixin.max-width(var.$mobile-res) {
    &__col1 {
      @include mixin.d-flex(false, center, column);
      margin-bottom: 30px;
    }
    &__col2 {
      margin-bottom: 10px;
    }
    &__nameExample {
      margin-top: -15px;
      margin-bottom: 30px;
    }
  }
}

.subscription-card {
  font-family: 'Gilroy', sans-serif;
  border-radius: 16px;
  padding: 40px 20px 20px;
  border: 1px solid var.$white_dark;
  background-color: var.$white;
  position: relative;

  &.premium__block__border {
    @extend %premium-bg;

    border-radius: 16px;
    border: 1px solid transparent;
  }

  .subscription__card__title {
    font-size: 32px;
    line-height: 1.44;
  }
}
