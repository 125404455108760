@use '../variables' as var;
@use "../mixins" as mixin;

//sign in, sign up registration pages global style
.registration-global {
  @include mixin.min-width(768px) {
    flex-direction: column;
    padding-top: 0;
  }
  @include mixin.min-width(1441px) {
    background: url("../../../assets/svg/registration-bg.jpg") center/cover no-repeat;
  }
  @media screen and (max-width: 1440px) and (min-width: 768px){
    background: url("../../../assets/svg/registration-bg-1440.jpg") center/cover no-repeat;
  }
  &__wrapper {
    flex: 1;
    @include mixin.max-width(767px) {
      background-color: var.$gray_ligh_2;
    }
    @include mixin.min-width(768px) {
      @include mixin.d-flex(false, center, column);
      align-self: flex-end;
      grid-row-gap: 20px;
      max-width: 504px;
      width: 100%;
      padding: 40px 0;
      background-color: var.$white;
      box-shadow: 0px 0px 24px 6px rgba(0, 0, 0, 0.12);
    }
  }
  .container {
    align-items: center;
    @include mixin.max-width(767px) {
      max-width: 540px;
      padding-top: 30px;
      padding-bottom: 20px;
    }
    @include mixin.min-width(768px) {
      flex: 1;
      justify-content: center;
      max-width: 355px;
    }
  }
  &__logo {
    @include mixin.min-width(768px) {
      max-width: 142px;
    }
    @include mixin.max-width(767px) {
      display: none;
    }
  }
  &__input-group {
    width: 100%;
  }
}