// Colors
$white: #fff;
$white_dark: #eaeaea;
$black: #2e2e2e;
$gray: #bfbfbf;
$gray_light: #f4f4f4;
$gray_ligh_2: #f5f5f5;
$gray_text: #878787;
$gray_text-light: #c0c0c0;
$gray_border: #ddd;
$gray_dark: #585858;
$gray_dark_2: #969696;
$premiumColor: radial-gradient(
                96.23% 85.68% at 14.33% 14.32%,
                #ce1ff6 0%,
                #575fee 100%
);

$violet: #c86dff;
$violet_light: #eac7ff;
$green: #6dffa8;
$success: #65BF7F;
$error: #e81515;
$red: #ff3b30;
$red_2: #ff6251;
$orange: #fc7b52;
$orange_light: #ffc5a4;
$orange_light_2: #ffebdf;
$orange_light_3: #fdbda8;
//traffic colors
$blue: #488eb0;
$green_2: #77b9b5;
$pink: #eb5a6b;

// hover colors for desktop
$hover_main: #f5f5f5;
$hoverMainDarker: #eaeaea;
$orangeHovered: #DF5A2F;
$warningHover:  #ffebdf;

// Radius
$radius_xs: 8px;
$radius_s: 16px;
$radius-m: 20px;
$radius-l: 32px;

// l-spacing
$lSpacing: 0.8px;
$lSpacing_xs: -0.18px;

// Dimensions
$logo-height: 22px;

// Resolutions
$mobile-res: 767.9px;
$tablet-res: 768px;
$tabletL-res: 1200px;
$laptopL-res: 1440px;

// transitions
$transitionGlobal: .3s;
$transition--500: .5s;
$transitionFast: .15s;


// Colorful text
%premium-color {
  background-image: radial-gradient(
    96.23% 85.68% at 14.33% 14.32%,
    #ce1ff6 0%,
    #575fee 100%
  );
  background-clip: text;
  color: transparent;
}

%premium-bg {
  background-image: radial-gradient(
    96.23% 85.68% at 14.33% 14.32%,
    #CE1FF6 0%,
    #575FEE 100%
  )
}

%premium-bg--lite {
  background: radial-gradient(
      96.23% 85.68% at 14.33% 14.32%,
      rgba(206, 31, 246, 0.14) 0%,
      rgba(87, 95, 238, 0.14) 100%
    ),
    $white;
}
