@use "../../../assets/sass/common-elements/buttons";
@use "../../../assets/sass/variables" as *;
@use "../../../assets/sass/mixins" as *;

.acc_edit {
  div[class*="container-inner"] {
    @include min-width($tablet-res) {
      padding-right: 0;
    }
  }
  .email_block {
    display: grid;
    grid-template-columns: 1fr 48px;
    grid-template-areas:
      "text icon"
      "email icon";
    column-gap: 12px;
    margin-bottom: 25px;
    &__text {
      grid-area: text;
    }
    &__email {
      grid-area: email;
    }
    &__icon {
      grid-area: icon;
    }
  }
  div[class*="MuiSelect-select"] {
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    color: #2e2e2e;
  }

  .deleteAccount {
    a {
      display: inline-block;
      margin-right: 0;
    }
  }
}

/* media */
@include min-width($tablet-res) {
  .acc_edit__wrapper {
    padding: 30px 30px 40px;
    max-width: 546px;
    > div {
      max-width: 315px;
      width: 100%;
      margin-inline: auto;
    }
  }
}
@include max-width($mobile-res) {
  .container {
    height: 100%;
  }
  .acc_edit__wrapper {
    @include d-flex($direction: column);
    flex: 1;
  }
}
