@use "../../../assets/sass/variables" as var;
@use "../../../assets/sass/mixins" as mixin;

.delete {
  .container {
    height: 100%;
  }
  &__warning {
    margin-bottom: 25px;
    img {
      margin: 0 auto 25px;
    }
  }
}
