@use "../../../assets/sass/variables.scss" as *;
@use "../../../assets/sass/mixins";

.home {
  @include mixins.d-flex();
  overflow-y: auto;
  transition: 500ms ease;
  .container {
    transition: 500ms ease;
    padding-inline: 16px;
    padding: 20px 16px 0 16px;
  }
}

.avatar div {
  width: 104px;
  height: 104px;
}

.links_stack {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0 -8px 15px;

  &__item {
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $black;
    border-radius: $radius_m;
    margin: 0 8px 15px 8px;
    font-size: 0;

    svg path {
      fill: $white;
    }
  }
}

.home_cards {
  margin-bottom: 20px;
  a[class*='UserLinkHome_olink_url'] {
    display: none;
  }
  div[class*='setting_card__top']:not(div[class*='setting_card__top__pro']) {
    h5[class*='setting_card__type'],
    div[class*='setting_card__text'] {
      padding-right: 0;
    }
  }
}

