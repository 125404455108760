@use '../../../assets/sass/variables' as var;
@use '../../../assets/sass/mixins';

.header {
  z-index: 101;
  top: 0;
  right: 0;
  left: 0;
  background: var.$white;
  box-shadow:
          0px 2px 10px rgba(88, 88, 88, 0.1),
          0px 4px 6px -2px rgba(88, 88, 88, 0.1);
  min-width: 320px;
  .container {
    display: grid;
    align-items: center;
    justify-items: center;
  }
  /* media */
  @include mixins.min-width(var.$tablet-res) {
    margin-bottom: -68px;
    padding: 14px 0;
    margin-left: 285px;
    margin-right: 30px;
    border-radius: 0px 0px 18px 18px;
    .container {
      grid-template-columns: 40px 1fr 40px;
      grid-template-rows: minmax(40px, auto);
      padding: 0 30px;
    }
  }
  @include mixins.min-width(1201px) {
    max-width: calc(100% - 700px);
  }
  @include mixins.max-width(var.$mobile-res) {
    position: fixed;
    padding: 10px 0;
    .container {
      grid-template-columns: 35px 1fr 35px;
      grid-template-rows: minmax(35px, auto);
    }
  }

  &__btn {
    position: relative;
    font-size: 0;
    width: 15px;
    height: 10px;
    background: transparent;

    &::before,
    &::after,
    span {
      @include mixins.pseudo;
      background: var.$black;
      width: 100%;
      height: 1px;
    }
    &::before {
      top: 0;
    }
    &::after {
      bottom: 0;
    }
    span {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__logo {
    //height: var.$logo-height;
    grid-column: 2 / 3;
  }

  &__icon {
    grid-column: 1 / 2;
     button[class*="MuiButton-root"] {
      padding: 0;
      min-width: min-content;
      & > div {
        width: 35px;
        height: 35px;
      }
    }
  }

  &__premium {
    grid-column: 3 / -1;
    align-self: center;
    justify-self: center;
    font-size: 0;
    background-color: transparent;
    width: 100%;
    height: 100%;
    @include mixins.min-width(var.$tablet-res) {
      border: 1px solid var.$white_dark;
      border-radius: var.$radius_xs;
      &.active {
        border-color: var.$orange_light_3;
      }
    }
    @include mixins.hover-supported {
      background-color: var.$hover_main;
    }
    &.active {
      img {
        filter: brightness(1);
      }
    }
    img {
      margin: auto;
      filter: brightness(0);
      transition: var.$transitionGlobal;
      @include mixins.min-width(var.$tablet-res) {
        width: 24px;
        height: 24px;
      }
    }

    span[class*='MuiBadge-badge'] {
      top: 5px;
      left: -1px;
      right: initial;
      min-width: auto;
      height: initial;
      padding: 2px 5px;
      background: var.$error;
      color: var.$white;
      border: 2px solid white;
      line-height: 1;
    }
  }

  div[class*="MuiDrawer-paper"] {
    @include mixins.min-width(var.$tablet-res) {
      background-color: var.$black;
      border: 0;
      box-shadow:
              0 2px 10px rgba(88, 88, 88, 0.1),
              0 4px 6px -2px rgba(88, 88, 88, 0.1);
      overflow-y: unset;
      //overflow-x: auto;
    }
  }
}
