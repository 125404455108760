@use "../mixins" as mixin;
@use "../variables" as var;


@mixin clr-scheme(
  $-bg-clr,
  $bgBlendMode: false,
  $-name-clr,
  $-interest-clr,
  $-location-clr,
  $-mainBtn-bg,
  $-mainBtn-bg-hvr,
  $-mainBtn-brdr-clr,
  $-mainBtn-brdr-clr-hvr,
  $-mainBtn-text-clr,
  $-mainBtn-text-clr-hvr,
  $-cards-bg-clr,
  $-box-shadow: false,
  $-social-icon-clr,
  $-cards-headers-clr,
  $-cards-text-clr,
  $-border-rds,
  $-border-clr,
  $-divider-clr,
  $-cards-icon-clr,
  $-cbox-text-clr,
  $-cbox-bg-clr-checked,
  $-cbox-bg-clr-unchecked,
  $-cbox-thumb-bg-clr-checked,
  $-cbox-thumb-bg-clr-unchecked,
  $-cbox-border-clr-unchecked,
  $-widget_bg,
  $-font-family,
  $-media-type-bg,
  $-media-type-icon-clr,
  $-logo-circle: false,
  $-logo-text: false,
  $-addColor: false,
  $-addBordColor: false,
  $-addIconColor: false,
  $-cardFormLabelColor: false,
  $-cardFormInputBg: false,
  $-cardFormInputColor: false,
  $-cardFormInputBorderColor: false,
) {
  background: $-bg-clr;
  @if($bgBlendMode) {
    background-blend-mode: soft-light, normal;
  }
  .logo {
    &__circle {
      @if $-logo-circle {
        fill: $-logo-circle;
      }
    }
    &__text {
      @if $-logo-text {
        fill: $-logo-text;
      }
    }
  }
  .name {
    color: $-name-clr;
  }
  .interest {
    color: $-interest-clr;
  }
  .location {
    color: $-location-clr;
    svg {
      fill: currentColor;
    }
  }
  p[class*='OlinkOwnerHome_social_text'] {
    color: $-location-clr;
  }

  .btn__custom {
    background: $-mainBtn-bg;
    border-color: $-mainBtn-brdr-clr;
    color: $-mainBtn-text-clr;
    @if $-box-shadow {
      box-shadow: $-box-shadow;
    }
    @if($bgBlendMode) {
      background-blend-mode: soft-light, normal;
    }

    @if $-border-rds == true {
      border-radius: 16px;
    } @else {
      border-radius: $-border-rds;
    }
/*    &:hover {
      background: $-mainBtn-bg-hvr !important;
      border-color: $-mainBtn-brdr-clr-hvr !important;
      color: $-mainBtn-text-clr-hvr !important;
    }*/
    svg path {
      fill: currentColor;
    }
/*    .btn__custom__icon {
      stroke: currentColor;
    }*/
  }

  img[class*='_cover__'],
  picture[class*='setting_card__icon'] {
    @if $-border-rds == true {
      border-radius: 6px;
    } @else {
      border-radius: $-border-rds;
    }
  }

  div[class*='setting_card__icon'],
  img[class*='setting_card__icon'] {
    @if $-border-rds == true {
      border-radius: 12px;
    } @else {
      border-radius: $-border-rds;
    }
  }

/*   .social_icon_home svg path {
     fill: $-social-icon-clr;
   }*/

   /*
   .social_icon_home:after {
     background: $-social-icon-clr;
     @if $-border-rds == true {
       border-radius: 12px;
     } @else {
       border-radius: $-border-rds;
     }
   }
   */

  .social-platforms-links__item {
    background: $-cards-bg-clr;
    @if $-border-rds == true {
      border-radius: 12px;
    } @else {
      border-radius: $-border-rds;
    }

    @if $-border-clr {
      border-color: $-border-clr;
    }

    @if $-box-shadow == true {
      box-shadow: 0px 2px 10px rgba(88, 88, 88, 0.1), 0px 4px 6px -2px rgba(88, 88, 88, 0.1);
    } @else if($-box-shadow == false) {

    } @else {
      box-shadow: $-box-shadow;
    }

    @if($bgBlendMode) {
      background-blend-mode: soft-light, normal;
    }
  }

  // admin home social link wrapper
  div[class*="OlinkOwnerHome_socialPlatforms"] {
    border-color: $-mainBtn-brdr-clr;

    @if $-border-rds == true {
      border-radius: 16px;
    } @else {
      border-radius: $-border-rds;
    }
    button[class*="OlinkOwnerHome_socialPlatforms__edit"] {
      background: $-cards-bg-clr;

      @if $-box-shadow {
        box-shadow: $-box-shadow;
      }
      @if $-border-clr {
        border: 1px solid $-border-clr;
      }
      @if($bgBlendMode) {
        background-blend-mode: soft-light, normal;
      }
    }

    button[class*="OlinkOwnerHome_socialPlatforms__save"] {
      background: $-mainBtn-bg;
      border-color: $-mainBtn-brdr-clr;
      color: $-mainBtn-text-clr;

      @if $-box-shadow {
        box-shadow: $-box-shadow;
      }
      @if($bgBlendMode) {
        background-blend-mode: soft-light, normal;
      }
    }
  }


  .home_cards .setting_card {
    //overflow: hidden;
    background: $-cards-bg-clr;
    @if $-box-shadow == true {
      box-shadow: 0px 2px 10px rgba(88, 88, 88, 0.1), 0px 4px 6px -2px rgba(88, 88, 88, 0.1);
    } @else if ($-box-shadow == false) {

    } @else {
      box-shadow: $-box-shadow;
    }
    @if $-border-rds == true {
      border-radius: 16px;
    } @else {
      border-radius: $-border-rds;
    }
    @if $-border-clr {
      border: 1px solid $-border-clr;
    }
    @if($bgBlendMode) {
      background-blend-mode: soft-light, normal;
    }
  }
  // for guest page desktop hover
  .home_cards.guest_page .setting_card {
    @include mixin.hover-supported {
     &:before {
       border-color:   $-cards-text-clr;
     }
    }
    &:before {
      @include mixin.pseudo($position: absolute);
      width: calc(100% - 6px);
      height: calc(100% - 6px);
      border: 1px solid transparent;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: border-color var.$transitionGlobal;
      @if $-border-rds == true {
        border-radius: 14px;
      } @else {
        border-radius: $-border-rds;
      }
    }
  }

  .social-platforms-links.guest_page .social-platforms-links__item {
    position: relative;
    &:before {
      @include mixin.pseudo($position: absolute);
      width: calc(100% - 6px);
      height: calc(100% - 6px);
      border: 1px solid transparent;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: border-color var.$transitionGlobal;
      @if $-border-rds == true {
        border-radius: 8px;
      } @else {
        border-radius: $-border-rds;
      }
    }
    @include mixin.hover-supported {
      &:before {
        border-color:   $-cards-text-clr;
      }
    }
  }

  div[class*='guest_page__col'] {
    @include mixin.min-width(var.$tablet-res) {
      box-shadow: 0px 12px 40px -6px rgba(46, 46, 46, 0.28);
      @if $-border-rds == true {
        border-radius: 16px;
      } @else {
        border-radius: $-border-rds;
      }
      @if $-border-clr {
        border: 1px solid $-border-clr;
      } @else {
        border: 1px solid var.$black;
      }
    }
  }

  // Inputs
  .home_cards {
    .MuiOutlinedInput-root, .MuiOutlinedInput-notchedOutline, .imgInput {
      @if $-border-rds == true {
        border-radius: 16px !important;
      } @else {
        border-radius: $-border-rds !important;
      }
    }
    .imgInput:not(.imgInput__added) {
      @if $-addBordColor {
        border-color: $-addBordColor;
      }
      @if $-addColor {
        color: $-addColor ;
      }
      @if $-box-shadow {
        box-shadow: $-box-shadow;
      }
      @if $-addIconColor {
        svg {
          rect {
            stroke: $-addIconColor;
          }
         > path:first-of-type{
            fill: $-addIconColor;
          }
        }
      }
    }
    // olink card edit inputs
    .setting_card__top__form .MuiFormControl-root  {
      .MuiInputLabel-root {
        @if $-cardFormLabelColor {
          color: $-cardFormLabelColor !important;
        }
      }
      .MuiOutlinedInput-root {
        @if $-cardFormInputBg {
          background-color: transparent !important;
        }
        @if $-cardFormInputColor {
          color: $-cardFormInputColor;
        }
        .MuiOutlinedInput-notchedOutline {
          @if $-cardFormInputBorderColor {
            border-color: $-cardFormInputBorderColor !important;
          }
        }
      }
    }
  }

  div[class*='setting_card__top'],
  button[class*='setting_card__edit'],
  button[class*='setting_card__delete'],
  button[class*='delete__yes'] {
    @if $-divider-clr {
      border-color: $-divider-clr;
    }
  }

  h5[class*='setting_card__type'],
  h5[class*='subscription__thanks'],
  button[class*='subscription__main_btn'] {
    color: $-cards-headers-clr;
  }
  div[class*='setting_card__text'],
  .dlt_clr__home {
    color: $-cards-text-clr;
  }

  .card__show,
  .card__hide {
    color: $-cbox-text-clr;
  }

  div[class*='checkbox__on'] {
    .card__show {
      color: #fc7b52;
    }
  }

  // cBox bg color
  .MuiSwitch-root.styled_checkbox .MuiSwitch-switchBase:not(.Mui-checked) + .MuiSwitch-track {
    @if($-cbox-bg-clr-unchecked) {
      background-color: $-cbox-bg-clr-unchecked;
    }
  }
  .MuiSwitch-root.styled_checkbox .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    @if($-cbox-bg-clr-checked) {
      background-color: $-cbox-bg-clr-checked;
    }
  }

  // cBox thumb bg color
  .MuiSwitch-root.styled_checkbox .MuiSwitch-switchBase:not(.Mui-checked) .MuiSwitch-thumb {
    @if($-cbox-thumb-bg-clr-unchecked) {
      background-color: $-cbox-thumb-bg-clr-unchecked;
    }
  }
  .MuiSwitch-root.styled_checkbox .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb {
    @if($-cbox-thumb-bg-clr-checked) {
      background-color: $-cbox-thumb-bg-clr-checked;
    }
  }

  // cBox border color
  .MuiSwitch-root.styled_checkbox .MuiSwitch-switchBase:not(.Mui-checked) + .MuiSwitch-track {
    @if ($-cbox-border-clr-unchecked) {
      border-color: $-cbox-border-clr-unchecked;
    }
  }




  @if($-cards-icon-clr) {
    // svg icons
    .icon-swap {
      path {
        fill: $-cards-icon-clr;
      }
    }
    .icon-delete {
      path {
        stroke: $-cards-icon-clr;
      }
    }

    .icon-pen {
      mask + path {
        fill: $-cards-icon-clr;
      }
      mask + path + path {
        fill: $-cards-icon-clr;
      }
      path[stroke] {
        stroke: $-cards-icon-clr;
      }
    }

    .icon-cross {
      path {
        stroke: $-cards-icon-clr;
      }
    }
  }


  //statistic widget bg
  .widget_bg {
    background-color: $-widget_bg;
  }

  // font
  @if $-font-family {
    h5[class*='setting_card__type'],
    div[class*='_setting_card__text'],
    button[class*='subscription__main_btn'],
    h5[class*='subscription__thanks'],
    div[class*='subscription'] input,
    .name,
    .interest,
    .location {
      font-family: $-font-family !important;
    }
  }

  // media type settings
  .setting_card__top  {
    &:not(.setting_card__top__pro) {
      picture.setting_card__icon svg {
        stroke: $-media-type-icon-clr;
      }
    }
    &.setting_card__top__pro picture svg{
      stroke: unset;
      > path:not(:first-of-type) {
        fill: $-media-type-icon-clr;
      }

      rect, > path:first-of-type {
        stroke: $-media-type-icon-clr;
      }
    }
    picture.setting_card__icon {
      background: $-media-type-bg;
      @if $-border-clr {
        border: 1px solid $-border-clr;
      }

    }
  }

}
