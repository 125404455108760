@use '../variables' as *;
@use "../mixins" as *;

.social-platforms-links {
  @include d-flex(false, center);

  &:not(&_preview-window) {
    grid-gap: 17px;
  }
  &_preview-window {
    justify-content: center;
    grid-gap: 10px;
  }

  &__item {
    border: 1px solid transparent;
  }

  /* media */
  @include max-width($mobile-res) {
    margin-bottom: 30px;
    justify-content: center;
    &__item {
      width: 71px;
      height: 71px;
    }
  }
  @include min-width($tablet-res) {
    max-width: 650px;
    width: 100%;
    margin-inline: auto;
    margin-bottom: 20px;
    &__item {
      width: 60px;
      height: 60px;
      padding: 9px;
    }
  }
  @include min-width(1201px) {
    &__item {
      border-radius: 12px;
      background-color: white;
      box-shadow:
              0px 2px 10px rgba(88, 88, 88, 0.1),
              0px 4px 6px -2px rgba(88, 88, 88, 0.1);
    }
  }
}

