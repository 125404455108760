*{
    padding: 0;
    margin: 0;
    border: 0;
}
*,*:before,*:after{
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 */
 html{
    scroll-behavior: smooth;
    height: 100%;
    line-height: 1.15; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */ 
}

body {height: 100%;min-width: 320px;text-rendering: optimizeSpeed;}
/*:focus:not(:focus-visible),:active:not(:focus-visible){outline: none;}
a:focus:not(:focus-visible),a:active:not(:focus-visible){outline: none;}*/

nav,footer,header,aside,canvas{display: block;}
input,button,textarea{font-family:inherit;}
input::-ms-clear{display: none;}
button, a {cursor: pointer;background-color: transparent;transition: .3s}
button::-moz-focus-inner {padding:0;border:0;}
a {text-decoration: none;}
ul li, li {list-style: none;}
img{max-width: 100%; vertical-align: top; display: block;}
picture{display: block}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
  &::first-line {
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    font-weight: bold;
  }
}
input[data-autocompleted] {
  background-color: transparent !important;
}

svg, canvas {
  max-width: 100%;
}