.mytol-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  border-radius: 18px;
  justify-content: center;
  text-align: center;
  padding: 20px;
  height: 170px;
  width: 100%;
  max-width: 260px;
  min-width: 180px;
  flex-grow: 1;
  margin-top: 10px;
}

@media (max-width: 1230px) {
  .mytol-card {
    padding: 10px;
    max-width: 200px;
  }
}

@media (max-width: 1100px) {
  .mytol-card {
    padding: 10px;
    max-width: 300px;
    // margin-top: 15px;
  }
}
.card-image {
  background-color: #fc7b52;
  border-radius: 12px;
  padding: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.card-feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 18px;
  text-align: center;
  height: 300px;
  width: 100%;
  min-width: 180px;
  overflow: hidden;

  max-width: 330px;

  @media (max-width: 1400px) {
    max-width: 300px;
  }

  @media (max-width: 1300px) {
    max-width: 260px;
  }

  @media (max-width: 1170px) {
    max-width: 230px;
  }
}
.feature-block {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
}

.pro-tips {
  display: flex;
  align-items: flex-start;
  background: #ffffff;
  border-radius: 18px;
  text-align: left;
  padding: 12px;
  width: 100%;
  min-width: 180px;
  gap: 12px;
  border: 2px solid transparent;
  background-image: linear-gradient(white, white),
    linear-gradient(#ce1ff6, #575fee);
  background-origin: border-box;
  background-clip: padding-box, border-box;

  height: 130px;

  @media (max-width: 1320px) {
    height: 155px;
  }

  @media (max-width: 1260px) {
    height: 100px;
  }

  @media (max-width: 1080px) {
    align-items: center;
  }

  max-width: 258px;

  @media (max-width: 1350px) {
    max-width: 230px;
  }

  @media (max-width: 1300px) {
    max-width: 220px;
  }

  @media (max-width: 1170px) {
    max-width: 200px;
  }

  @media (max-width: 1260px) {
    max-width: unset;
  }
}

.pro-content {
  background: linear-gradient(#ce1ff6, #575fee);
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  flex-shrink: 0;
}

.welcome-content {
  background: #ffebdf;
  display: flex;
  justify-content: space-between;
  border-radius: 24px;
  position: relative;
  overflow: hidden;
  min-height: 260px;
}

.welcome {
  margin: auto 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  max-width: 450px;
  margin-left: 50px;

  @media (max-width: 510px) {
    margin-left: 30px;
  }
}

.simple-step {
  &.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #eaeaea;
    margin-top: 50px;
    border-radius: 16px;
    height: 578px;
    width: unset;

    @media (max-width: 1100px) {
      height: 570px;
    }

    @media (max-width: 360px) {
      width: unset;
    }

    @media (min-width: 438px) and (max-width: 1100px) {
      width: 414px;
      margin-left: auto;
      margin-right: auto;
    }

    @media (min-width: 360px) and (max-width: 438px) {
      width: 320px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  &.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-items: center;
    overflow: hidden;
    width: 100%;
    position: relative;
    grid-template-rows: auto;

    @media (min-width: 1101px) {
      display: grid;
      grid-template-columns: repeat(3, minmax(200px, 1fr));
      flex-direction: unset;
      align-items: start;
      justify-items: center;
    }

    @media (max-width: 1100px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: hidden;
    }
  }
  &.img {
    text-align: center;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 250px;
    margin-top: 30px;

    height: unset;
    overflow: unset;
    position: unset;
    width: unset;
    margin-bottom: unset;

    @media (max-width: 1100px) {
      height: 450px;
      overflow: hidden;
      position: relative;
      width: 200px;
      margin-bottom: 60px;
    }
  }
  &.swiper-block {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.swiper-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    max-width: 320px;
    z-index: 2;
    position: absolute;
    top: 73%;
    transform: translateY(-50%);
    // background: #ffffff;
    border-radius: 18px;
    height: 176px;
    @media (max-width: 438px) {
      width: 90%;
    }
    @media (max-width: 1100px) {
      top: 76%;
      height: 220px;
    }
  }
}

.key-features {
  &.block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    height: 514px;
    background: unset;
    border-radius: 18px;
    width: unset;

    @media (max-width: 1100px) {
      background: #eaeaea;
      width: 414px;
    }

    @media (max-width: 438px) {
      width: 320px;
    }

    @media (max-width: 330px) {
      width: 290px;
    }

    @media (min-width: 1101px) {
      width: 100%;
    }
  }
}

.welcome-screen {
  &.sidebar {
    @media (max-width: 1285px) {
      max-width: 370px;
    }
    @media (max-width: 1100px) {
      // max-width: 400px;
      display: none;
    }
  }
  &.key-features {
    max-width: 224px;
    max-height: 410px;
    @media (max-width: 1150px) {
      max-width: 220px;
    }
    @media (max-width: 1130px) {
      max-width: 215px;
    }
    @media (max-width: 1110px) {
      max-width: 210px;
    }
  }
  &.screen-content {
    width: 750px;
    margin-top: 50px;
    margin-left: 40px;

    @media (max-width: 1150px) {
      margin-left: 70px;
    }
    @media (max-width: 1100px) {
      margin-left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  &.welcome-tips {
    max-width: 700px;
    @media (max-width: 1165px) {
      max-width: 680px;
    }

    @media (max-width: 1120px) {
      max-width: 650px;
    }
    @media (max-width: 1075px) {
      max-width: 620px;
    }
    @media (max-width: 700px) {
      max-width: 500px;
    }
    @media (max-width: 600px) {
      max-width: 400px;
    }
    @media (max-width: 430px) {
      max-width: 300px;
    }
  }
  &.welcome-simple-content {
    background: #ffffff;
    border-radius: 18px;
    padding: 8px;
    display: flex;
    max-width: 500px;
    height: auto;
    margin-bottom: 20px;
    position: relative;
    z-index: 1;
    align-items: center;
    @media (max-width: 1100px) {
      background: none;
      align-items: flex-start;
    }
  }
  &.welcome-simple-block {
    position: relative;
    max-width: 800px;
    margin-top: 40px;
    padding: 20px;
    @media (max-width: 1100px) {
      background: none;
      // position: absolute;
    }

    @media (max-width: 425px) {
      background: none;
      max-width: 350px;
    }
  }
  &.welcome-simple-img {
    background: #fc7b52;
    border-radius: 12px;
    padding: 8px;
    width: 40px;
    height: 40px;
    gap: 10px;
    margin-right: 10px;
    @media (max-width: 1100px) {
      background: #2e2e2e;
      margin-top: 5px;
    }
  }
  &.welcome-color {
    color: #fc7b52;
    @media (max-width: 1100px) {
      color: #2e2e2e;
    }
  }

  &.welcome-position-img {
    position: absolute;
    top: -60px;
    left: 60%;
    width: 240px;
    height: 503px;
    z-index: 2;
    @media (max-width: 1100px) {
      // position: relative;
      // z-index: 0;
      left: 23%;
    }
    @media (max-width: 800px) {
      // position: relative;
      // z-index: 0;
      left: 23%;
    }
    @media (max-width: 425px) {
      // position: relative;
      // z-index: 0;
      left: 15%;
    }
  }
  &.welcome-position-relative {
    @media (max-width: 1100px) {
      margin-top: 180px;
      position: relative;
      z-index: 5;
      background: rgb(234, 234, 234);
    }
  }
  &.welcome-header {
    @media (max-width: 1100px) {
      margin-bottom: 20px;
    }
  }
}

.padding-left-20 {
  padding-left: 20px;
}
