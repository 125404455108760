@use '../../../assets/sass/common-elements/forms';
@use '../../../assets/sass/variables.scss' as var;
@use '../../../assets/sass/mixins';

.newsletter {

  &__list {
    padding: 24px 30px;
    @include mixins.min-width(var.$tablet-res) {
      max-width: 546px;
      width: 100%;
      align-self: center;
    }
    div {
      &:not(:first-of-type) {
        border-top: 1px solid var.$white_dark;
        padding-top: clamp(0.875rem, 4vw - 1rem, 1rem); // 16px - 14px
      }
      &:not(:last-of-type) {
        padding-bottom: clamp(0.875rem, 4vw - 1rem, 1rem); // 16px - 14px
      }
      label {
        span {
          color: var.$black;
          font: 600 1.125rem/1 Gilroy, sans-serif !important;
          letter-spacing: var.$lSpacing_xs;
        }
      }
    }
  }
}

// some styles for newslatter in forms.scss
