@use '../../../assets/sass/variables' as var;

.delete {
  .container {
    height: 100%;
  }

  &__warning {
    margin-bottom: 25px;
    img {
      margin: 0 auto 25px;
    }
  }
}
