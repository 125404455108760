/* montserrat-regular - latin */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''),
       url('/assets/fonts/montserrat-v18-latin-regular.woff2') format('woff2');
}

/* montserrat-500 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local(''),
       url('/assets/fonts/montserrat-v18-latin-500.woff2') format('woff2');
}

/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local(''),
       url('/assets/fonts/montserrat-v18-latin-600.woff2') format('woff2');
}

/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(''),
       url('/assets/fonts/montserrat-v18-latin-700.woff2') format('woff2');
}

/* sf-pro-rounded-400 */
@font-face {
  font-family: 'SF-Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''),
       url('/assets/fonts/SF-Pro-Rounded-Regular.woff2') format('woff2');
}
/* sf-pro-rounded-600 */
@font-face {
  font-family: 'SF-Pro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local(''),
       url('/assets/fonts/SF-Pro-Rounded-Semibold.woff2') format('woff2');
}

/* gilroy-400 */
@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''),
       url('/assets/fonts/Gilroy-Regular.woff2') format('woff2');
}
/* gilroy-600 */
@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local(''),
       url('/assets/fonts/Gilroy-SemiBold.woff2') format('woff2');
}
/* gilroy-700 */
@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(''),
       url('/assets/fonts/Gilroy-Bold.woff2') format('woff2');
}


@font-face {
  font-family: 'Roboto';
  src: local(''),
       url('/assets/fonts/Roboto-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
