@use './templates_mixin' as *;
@use './templates_colors' as *;

@mixin input-bg($input-bg) {
  @if $input-bg {
    div[class*='MuiInputBase-root'] {
      background: transparent !important;
      color: $input-bg;
    }
  }
}

.home.custom {
  @include clr-scheme(
    var(--main-bg, $default),
    //main bg,
    false,
    //bg blend mode
    var(--name-clr, $black),
    //name
    var(--interest-clr, $black),
    //interest
    var(--location-clr, $black),
    //location
    var(--add-bg-clr, transparent),
    //add clr
    var(--add-bg-clr, transparent),
    // add brdr clr hover
    var(--add-brdr-clr, $black),
    // add brdr clr
    var(--add-brdr-clr, $black),
    // add brdr clr hover
    var(--add-text-clr, $black),
    // add text clr
    var(--add-text-clr, $black),
    // add text clr hover
    var(--tiles-bg, $black),
    // tiles bg clr
    var(--box-shadow, false),
    //box-shadow
    var(--social-icons-clr, $white),
    //social icons clr
    var(--tiles-haeder-clr, $white),
    //tiles header clr
    var(--tiles-text-clr, $default),
    //tiles text clr
    var(--brdr-radius, 0px),
    // border radius
    var(--brdr-clr, transparent),
    // border color
    var(--divider-clr, $gray),
    // divider clr
    var(--tiles-icon-clr, $gray_2),
    // tiles icons clr
    var(--chckbx-text-clr, $gray_4),
    //checkbox text clr
    var($orange),   //checkbox bg color checked
    var(--cbox-bg-clr-unchecked, $gray),   //checkbox bg color unchecked
    var(--cbox-thumb-bg-clr-checked, $black),  //checkbox thumb bg color checked
    var(--cbox-thumb-bg-clr-unchecked,  $gray_dark), //checkbox thumb bg color unchecked
    var(--cbox-border-clr-unchecked,  $gray_dark), //checkbox border color unchecked
    rgba(black, 0.3),
    //statistic widget bg,
    var(--f-family, 'Gilroy', sans-serif),
    // font-family
    var(--media-type-bg-clr, $gray),
    // media type bg
    var(--name-clr, $black),
    // media type svg color
    false, // logo circle color
    false, // logo text color
    false, // add img input label text color
    var(--input-border-clr, $gray_dark), // add img input label border color
    false, //add img input icon svg color
    false, // card edit form label color
    false, // card form input bg
    var(--input-clr, $white), // card form input color
    var(--input-border-clr, $gray_dark), // card form input border color
  );

  // main button
  .link_save_home {
    color: $orange;
    border: 1px solid $orangeLight;
    box-shadow: none;
    svg path {
      fill: currentColor;
    }
    @media (any-hover: hover) {
      &:hover {
        color: $orange !important;
        border: 1px solid $orange !important;
        background: transparent !important;
      }
    }
  }
  @include input-bg(var(--inputs, white));
}
