@use "../../../assets/sass/variables" as var;
@use "../../../assets/sass/mixins";

.privacy_policy {
  counter-reset: privacy-header;
  // policy for landing page
  &__landing {
    margin-left: 0 !important;
    div[class*='page-title'] {
      width: calc(100% - 60px);
    }
  }

  &__wrapper {
    @include mixins.adaptiv-value("padding-right", 24, 20, 1);
    @include mixins.adaptiv-value("padding-top", 30, 20, 1);
    @include mixins.adaptiv-value("padding-bottom", 30, 20, 1);
    @include mixins.min-width(var.$tablet-res) {
      max-width: 678px;
      width: 100%;
      align-self: center;
    }
  }
  &__card {
    overflow-y: auto;
    @include mixins.adaptiv-value("padding-right", 10, 20, 1);
    @include mixins.scroll-bar(false, var.$white_dark);
    @include mixins.min-width(var.$tablet-res) {
      height: calc(100vh - 270px);
    }
    @include mixins.max-width(var.$mobile-res) {
      height: calc(100vh - 205px);
    }
    &::-webkit-scrollbar {
      @include mixins.adaptiv-value("width", 3, 2, 1);
    }
  }

  &__header {
    margin-bottom: 10px;
    font-weight: 600;
    color: var.$black;
    // &::before {
    //   margin-right: 7px;
    //   counter-increment: privacy-header;
    //   content: counter(privacy-header);
    // }
  }

  &__text {
    margin-bottom: 20px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.57;
    color: var.$gray_dark_2;
  }
}

.dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #000;
  border-radius: 50%;
  margin-right: 5px;
  margin-left: 20px;
}
