@use '../../../assets/sass/variables' as *;
@use '../../../assets/sass/mixins' as mix;

// events grid for grid toggle
.event-wrapper-grid {
  @include mix.min-width(1400px) {
    max-width: 678px;
    width: 100%;
    margin: 0 auto;
  }

  // when selected "col" grid toggle
  &__col {
    @include mix.min-width($tablet-res) {
      display: grid;
      grid-template-columns: repeat(auto-fill, 331px);
      grid-auto-rows: max-content;
      align-items: flex-start;
      justify-content: center;
      grid-column-gap: 16px;

      .month-title {
        grid-column: 1/-1;
        &:not(:first-of-type) {
          margin-top: 10px;
        }
      }
    }


    .event__top {
      padding: 25px 30px 16px;
    }
    .rangeWrapper h5 {
      margin-bottom: 24px;
    }
    .range {
      padding: 8px 16px;
      border: 1px solid $white_dark;
      border-radius: $radius_xs; //8px
    }

    .details__item h5 {
      margin-bottom: 10px;
    }
    .details__text:not(:last-of-type) {
      margin-bottom: 5px;
    }
  }

  // when selected "row" grid toggle
  &__row {
    @include mix.min-width($tablet-res) {
      .showRules {
        .event__top {
          padding: 19px 30px;
        }
        .rangeWrapper:after {
          transform: rotate(-90deg);
        }
        .rangeWrapper .range {
          opacity: 0;
          visibility: hidden;
        }
      }
      .event:not(.showRules) .event__top {
        padding: 8px 30px;
      }

      .rangeWrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 15px;
        grid-column-gap: 30px;
        align-items: center;
        cursor: pointer;
        &:after {
          @include mix.chevron();
          transform: rotate(90deg);
        }
        .range {
          display: block;
        }
        .range:after {
          display: none;
        }
        > .range p{
          line-height: 1.375;
        }
      }
      .range {
        max-width: 276px;
      }
      .details__item {
        display: grid;
        align-items: flex-start;
        grid-template-columns: 89px 1fr;
        grid-column-gap: clamp(1.25rem, 3vw - 1rem, 1.5rem); // 20px - 24px
        h5 {
          font-size: 1rem;
          line-height: 1.38;
        }
      }


    }
  }

  .month-title {
    padding-left: 30px;
    padding-right: 30px;
    p {
      @include mix.adaptiv-value("font-size", 18, 16, 1);
    }
  }
}

// events card
.event {
  transition: $transitionGlobal;
  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
  &.showRules {
    @include mix.min-width($laptopL-res) {
/*      position: relative;
      margin-bottom: -100% !important;
      z-index: 100;*/
      box-shadow: 0px 20px 40px -6px rgba(46, 46, 46, 0.28);
    }
  }
  &__top {
    border-bottom: 1px solid $white_dark;
    transition: padding $transitionGlobal;
  }

  .range {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-column-gap: 16px;
    //align-items: center;
    font-weight: 600;
    color: $gray_dark;
    transition: $transitionGlobal;
    @media (min-width: 450px) and (max-width: 767px) {
      justify-items: center;
    }
    &:after {
      @include mix.pseudo();
      background-color: $white_dark;
      height: calc(100% - 10px);
      width: 1px;
      grid-column: 2/3;
      grid-row: 1/1;
      align-self: center;
    }
    &__progress {
      margin-top: 14px;
      margin-bottom: 20px;
      background: $white_dark;
      height: 2px;
      border-radius: 2px;
      & > span {
        background: $orange;
      }
    }
    &__all_time {
      > div {
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        column-gap: 10px;
        width: max-content;
      }
      .light_text {
        margin-bottom: 3px;
      }
    }
  }

  .equal_column {
    max-width: max-content;
    &__row {
      line-height: 1;
      margin-bottom: 7px;
    }
    .light_text {
      margin-bottom: 7px;
    }
  }

  .light_text {
    font-weight: 400;
    color: $gray_dark;
  }

  .rules {
    @include mix.d-flex(space-between, center);
    min-height: 38px;
    padding-left: 16px;
    border: 1px solid $white_dark;
    border-radius: 6px;
    background-color: $gray_ligh_2;
    color: $gray_dark;

    &__more {
      display: grid;
      grid-auto-flow: column;
      align-items: center;
      column-gap: 13px;
      padding: 9px 22px 9px 10px;
      background: transparent;
      font: 400 1rem/1 Gilroy, sans-serif;
      color: $gray_dark;
      letter-spacing: -0.18px;
      @include mix.hover-supported {
        color: $orange;
        svg {
          fill: $orange;
        }
      }

      svg {
        transform: rotate(90deg);
      }

      &_active svg {
        transform: rotate(-90deg);
      }
    }
  }

  .details {
    position: relative;
    margin-top: 16px;
    //overflow: hidden;
    &:before {
      @include mix.divider(
              $width: calc(100% + 30px),
              $height: 1px,
              $left: 50%,
              $color: $white_dark);
      transform: translateX(-50%);
    }
    &__item {
      position: relative;
      background-position: top 13px left;
      background-repeat: no-repeat;
      &:not(:first-of-type) {
        border-top: 1px solid $white_dark;
      }
      &:not(:last-of-type) {
        padding: 14px 0 14px 33px;
      }
      &:last-of-type {
        padding: 15px 0 0 33px;
      }
      &-duration {
        background-image: url("../../../assets/svg/calendar--orange.svg");

        .details__text .light_text {
          min-width: 69px;
        }
      }
      &-device {
        background-image: url("../../../assets/svg/phone--orange.svg");
      }
      &-browser {
        background-image: url("../../../assets/svg/earth--orange.svg");
      }
      &-source {
        background-image: url("../../../assets/svg/path--orange.svg");
      }
      &-locate {
        background-image: url("../../../assets/svg/flag--orange.svg");
      }
      &-location {
        background-image: url("../../../assets/svg/pin--orange.svg");
      }
    }
    &__text {
      .light_text {
        display: inline-block;
        min-width: 56px;
        margin-right: 10px;
      }
    }
    &__text, &__list {
      @include mix.d-flex();
      font: 600 0.875rem/1.5 Montserrat, sans-serif;
      color: $gray_dark;
    }
    &__list {
      flex-wrap: wrap;
      grid-gap: 4px 8px;
    }
  }

  // bottom controls
  &-bottom {
    padding-inline: 25px;
    &__control {
      padding: 13px 5px;
      font: 600 1rem/1 'Gilroy', sans-serif;
      letter-spacing: -0.18px;
      color: $gray_dark;
      @include mix.hover-supported {
        color: $orange;
      }
    }

    // max-width 767.9px and min-width: 425px
    @media screen and (max-width: $mobile-res) and (min-width: 425px) {
      :nth-child(2) {
        margin-inline: auto 10px;
      }
    }
    @include mix.max-width(359.9px) {
      padding-inline: 10px;
    }
  }
}
