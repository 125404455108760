@use '../../../assets/sass/variables' as *;
@use '../../../assets/sass/mixins';

.notification {

  &__timeline {
    @include mixins.adaptiv-value("padding-top", 30, 12, 1);
    @include mixins.adaptiv-value("padding-bottom", 30, 12, 1);
    @include mixins.adaptiv-value("padding-left", 66, 12, 1);
    @include mixins.adaptiv-value("padding-right", 66, 12, 1);
    @include mixins.min-width($tablet-res) {
      max-width: 546px;
      width: 100%;
      margin: 0 auto;
    }
  }
  &__empty {
    margin-top: 100px;
    figure {
      max-width: 200px;
      margin: 0 auto 20px;
    }
    p {
      font: 400 0.875rem/1.5 'Montserrat', sans-serif;
    }
  }
}

.timeline {
  display: grid;
  grid-template-columns: 48px 1fr max-content;
  align-items: center;
  column-gap: 12px;
  grid-template-areas: 'icon text time';
  &:not(:first-of-type) {
    padding-top: 6px;
  }
  &:not(:last-child):after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: $white_dark;
    grid-column: 2 / 3;
    grid-row: -1;
    margin-top: 6px;
  }

  &__icon {
    grid-area: icon;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-radius: $radius_xs;
    img {
      margin: auto;
    }
  }

  &__text {
    grid-area: text;
    h6 {
      font: 600 0.875rem/1.5 'Gilroy', sans-serif;
      color: $black;
    }
    p {
      font: 400 0.875rem/1.5 'Gilroy', sans-serif;
      color: $gray_dark_2;
    }
  }

  &__time {
    grid-area: time;
    font: 400 0.875rem/1 'Montserrat', sans-serif;
  }

  //   if opened

  &__opened {
    .timeline__text {
      h6,
      p {
        color: $gray_dark_2;
      }
    }

    .timeline__time {
      color: $gray_dark_2;
    }
  }
}
