@use 'src/assets/sass/variables' as var;
@use 'src/assets/sass/mixins';

// type text
#root .MuiFormControl-root, .MuiModal-root .MuiFormControl-root {
  width: 100%;
  margin-bottom: 20px;

  .MuiInputLabel-root {
    font-family: 'Montserrat', sans-serif;
    line-height: 1.5rem;
    left: 3px;
    // TODO - Need to modify selector
    &:not(.Mui-focused, .MuiFormLabel-filled, #olinks-multiselect-label) {
      @include mixins.min-width(768px) {
        transform: translate(14px, 12px) scale(1);
      }
      @include mixins.max-width(767px) {
        transform: translate(14px, 16px) scale(1);
      }
    }
    .Mui-focused {
      color: var.$orange !important;
    }
    &.Mui-disabled {
      display: none;
    }
    &.Mui-focused, &.MuiFormLabel-filled {
      letter-spacing: -0.02em;
    }
  }
  &.input_darker .MuiOutlinedInput-root {
    background: var.$gray_light;
    padding-left: 14px;
    padding-right: 14px;
    .MuiInputAdornment-root {
      margin-left: 0;
      button {
        position: relative;
        margin: 0;
        padding: 0;
        &:before {
          @include mixins.pseudo($content: url("../../svg/calendar--gray.svg"));
          width: 24px;
          height: 24px;
        }
        svg{
          display: none;
        }
      }
    }
    ::placeholder {
      color: var.$gray_dark_2;
      font-weight: 400;
    }
  }
  .MuiOutlinedInput-root {
    background-color: var.$white;
    padding-inline: 18px;
    @include mixins.adaptiv-value('padding-top', 12, 16, 1);
    @include mixins.adaptiv-value('padding-bottom', 12, 16, 1);
    font-weight: 500;
    border-radius: var.$radius_s;
    // input hover
    @include mixins.hover-supported {
      .MuiOutlinedInput-notchedOutline {
        border-color: var.$orange_light_3;
      }
    }
    // input focus
    &.Mui-focused .MuiOutlinedInput-notchedOutline{
      border-color: var.$orange_light_3!important;
    }
    .MuiOutlinedInput-input {
      padding: 0;
    }

    .MuiOutlinedInput-notchedOutline {
      padding: 0 11px;
      border-radius: var.$radius_s;
      border: 1px solid var.$white_dark;
      transition: border-color 300ms ease;
      legend{
        font-size: 14px;
      }
    }

    // styled eye type password
    button[aria-label='toggle password visibility'] {
      margin-right: -6px;
      &.Mui-focusVisible {
        @include mixins.focus-visible-default;
      }
      svg {
        fill: transparent;
        stroke: gray;
      }
    }
  }

  .MuiChip-root {
    background-color: #fff;
    border: 1px solid var.$orange_light_2;
    margin: 0px;

    &::after {
      display: none !important;
    }
  }

  label {
    color: var.$gray_dark_2;
    background: transparent !important;
    padding-right: 15px;
  }

  p.Mui-error {
    line-height: 1;
    font-size: 0.813rem; // 13px
    font-family: 'Montserrat', sans-serif;
    color: var.$error;
  }

  input, .MuiSelect-select {
    font: normal 600 1rem/1.38 'Montserrat', sans-serif;
    text-overflow: ellipsis;
    border-radius: 0;
    letter-spacing: normal;
  }
}

label.Mui-focused {
  color: var.$orange !important;
}


// red border due error
.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: var.$error !important;
}

// checkbox styles
.checkbox-wrapper {
  padding: 11px 0;
}
#root .MuiFormControlLabel-root {
  display: grid;
  grid-template-columns: 25px minmax(auto, max-content);
  justify-content: center;
  grid-column-gap: 11px;
  margin: 0 0 5px 0;
  padding-bottom: 11px;
  text-align: center;
  color: var.$gray_text;

  .MuiTypography-root {
    text-align: left;
  }

  span {
    line-height: 1;
    font-size: mixins.rem(14); // 14px
    font-family: 'Montserrat', sans-serif;
    padding: 0;
  }
/*  .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
    padding: 9px 0;
  }*/

  .MuiSvgIcon-root {
    fill: var.$gray_dark_2;
  }

  //radio button
  .Mui-checked {
    .MuiSvgIcon-root {
      fill: var.$orange;
      &[data-testid="RadioButtonCheckedIcon"] {
        transform: scale(1.4);
      }
    }
  }
  .MuiSvgIcon-root[data-testid="RadioButtonUncheckedIcon"] {
    transform: scale(1.1);
  }
}




// styled switch (.MuiSwitch-root)
.MuiSwitch-root.styled_checkbox {
  padding: 0 !important;
  @include mixins.min-width(var.$tablet-res) {
    width: 52px;
    height: 26px;
  }
  @include mixins.max-width(var.$mobile-res) {
    width: 64px;
    height: 32px;
  }
  .MuiSwitch-switchBase {
    padding: 0;
    @include mixins.min-width(var.$tablet-res) {
      top: 3px;
      left: 3px;
    }
    @include mixins.max-width(var.$mobile-res) {
      top: 4px;
      left: 4px;
    }
    .MuiSwitch-thumb {
      background: var.$gray_dark;
      box-shadow: none;
      @include mixins.min-width(var.$tablet-res) {
        width: 20px;
        height: 20px;
      }
      @include mixins.max-width(var.$mobile-res) {
        width: 24px;
        height: 24px;
      }
    }
    &.Mui-checked {
      @include mixins.min-width(var.$tablet-res) {
        transform: translateX(26px);
      }
      @include mixins.max-width(var.$mobile-res) {
        transform: translateX(32px);
      }
      & + .MuiSwitch-track {
        background: var.$orange;
        border-color: transparent;
      }
      .MuiSwitch-thumb {
        background: var.$white;
      }
    }
  }
  .MuiSwitch-track {
    opacity: 1 !important;
    background: var.$gray_light;
    border: 1px solid var.$white_dark;
    border-radius: var.$radius-l;
  }
}

// for accessibility
/*.MuiTouchRipple-root {
  transition: box-shadow var.$transitionGlobal;
}*/
.MuiSwitch-root.styled_checkbox .Mui-focusVisible .MuiTouchRipple-root,
.MuiRadio-root.Mui-focusVisible .MuiTouchRipple-root,
.MuiCheckbox-root.Mui-focusVisible .MuiTouchRipple-root {
  @include mixins.focus-visible-default;
}

// home_cards switch with margin
.home_cards .MuiSwitch-root{
  margin: 0 15px;
}

// styled label with checkbox (e.g '/add-socials', '/settings')
.styled_label {
  justify-content: space-between !important;
  grid-template-columns: max-content minmax(auto, max-content) !important;
  padding: 0 !important;
  margin: 0 !important;
  min-height: 80px;
  color: var.$gray_dark !important;
  user-select: none;

  span:first-child {
    order: 1;
  }

/*  span:nth-child(2) {
    font-size: 1rem !important;
    font-family: 'Gilroy', sans-serif !important;
  }*/

  // checkbox

  .MuiSwitch-root {
    @extend .styled_checkbox;
  }
}

// styled dropdown arrow and clear button
#root .styled_drop_arrow {
  &.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root {
    padding-right: 65px;
  }
  .MuiAutocomplete-endAdornment {
    .MuiAutocomplete-popupIndicator {
      position: relative;
      width: 24px;
      height: 24px;
      right: 7px;
      padding: 4px;
      transition: .3s ease;
      &::after {
        @include mixins.chevron(
                        absolute,
                $right: -2px,
                $top: 6px,
                $colorBlack: false,
                $transform: rotate(90deg)
        );
      }
      svg {
        display: none;
      }
    }
    .MuiAutocomplete-clearIndicator {
      width: 24px;
      height: 24px;
      padding: 0;
      right: 10px;
      svg {
        fill: var.$orange;
      }
    }
  }
  div[role="button"]{
    position: relative;
    &::after {
      @include mixins.chevron(
              absolute,
              6px,
              5px,
              false,
              rotate(90deg)
      );
      transform-origin: center;
    }
  }
  div[aria-expanded="true"]:after {
    transform: rotate(-90deg);
  }
}

//====== exstra styles to AddSocials.module.scc ======

.socials {
  &__items {
    // single item
    & > * {
      border-radius: 16px !important;
      margin-bottom: 20px !important;
      overflow: hidden;
      box-shadow:
              0px 2px 10px rgba(88, 88, 88, 0.1),
              0px 4px 6px -2px rgba(88, 88, 88, 0.1) !important;
      &::before {
        display: none;
      }

      & > [id] {
        padding: 0 30px;
        transition: border-color 300ms ease;
        border-bottom: 1px solid transparent;

        &[aria-expanded='true'] {
          border-color: var.$gray_ligh_2;
        }

        & > *:first-child {
          margin: 28px 0;
          font: 600 1rem/1 Gilroy, sans-serif;
        }
      }

      // hidden menu
      .MuiCollapse-root {
        .MuiAccordionDetails-root {
          padding: 0 30px 15px;

          // hidden menu item
          div + div {
            border-top: 1px solid var.$white_dark;
          }
          div {
            label {
              @extend .styled_label;
            }
          }
        }
      }
    }
  }
}

//===== styles for dialogs

.MuiDialog-paper {
  margin: 20px !important;
  text-align: center;
  border-radius: var.$radius-s !important;
  min-width: 280px;

  .MuiDialogContent-root,
  .MuiDialogActions-root {
    padding: 0;
  }
}

//===== styles for newslatter

.newsletter {
  &__list div label {
    @extend .styled_label;
    min-height: auto;
  }
}

//===== styles for personalization

.personalization_btn {
  div {
    label {
      @extend .styled_label;
      min-height: auto;
      color: var.$black !important;
      padding: 12px 0 !important;
    }
    & + div {
      border-top: 1px solid var.$white_dark;
    }
  }
}

.hide_logo_component {
  label {
    @extend .styled_label;
    min-height: auto;
    color: var.$black !important;

    span:nth-child(2) {
      font-size: 1.125rem !important;
      font-weight: 600;
    }
  }
}


//img input


.imgInput {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  justify-content: center;
  column-gap: 40px;
  cursor: pointer;
  padding: 15px 20px;
  font-weight: 500;
  color: var.$orange;
  border-radius: 16px;
  border: 1px solid var.$white_dark;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  transition: var.$transitionGlobal;
  @include mixins.hover-supported {
    background-color: var.$hover_main;
  }


  &__change {
    right: 41px;
    border-radius: var.$radius_s 0 0 0;
  }

  &__del {
    right: 0;
  }

  &__change, &__del {
    position: absolute;
    display: none;
    bottom: 0;
    padding: 8px;
    background: #c0c4c5;
    z-index: 10;
  }

  &__added {
    height: 175px;
    .imgInput__change,
    .imgInput__del {
      display: block;
      transition: var.$transitionGlobal;
      @include mixins.hover-supported {
        background-color: var.$white;
      }
    }
  }
  .cover {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    margin-bottom: 20px;
    object-fit: cover;
    object-position: center;
  }
  .imgCropper {
    display: none;
  }
}




