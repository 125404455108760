$default: #f5f5f5;
$orange: #fc7b52;
$orangeLight: #fdbda8;
$dark: #484848;
$white: #ffffff;
$black: #2e2e2e;
$gray: #585858;
$gray_dark: #969696;
$gray_2: #eaeaea;
$gray_3: #464646;
$gray_4: #c0c0c0;
$tree: #589167;
$green: #547b7a;
$sky: #0c4271;
$flat: #f2e9e1;
$neo: #eaecf0;
$glow: linear-gradient(226.42deg, #3a0e41 8.93%, #09152f 110.98%);
$glass: linear-gradient(165.1deg, #01dcba 8.6%, #7f30cb 103%);
$trans: rgba(255, 255, 255, 0.44);
$trans_dark: rgba(192, 192, 192, 0.24);
$pink: #f9dfdc;
$neoGradient: (linear-gradient(135.37deg, rgba(0, 0, 0, 0.4) 4.29%, rgba(255, 255, 255, 0.4) 95.6%), #EAECF0);
