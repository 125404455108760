@use "../mixins" as *;
@use "../variables" as *;

.img-cropper {
  &__body {
    min-height: 300px;
    max-width: 300px;
    width: 100%;
  }
  .cropper-point, .cropper-point:before, .cropper-line {
    background-color: $orange;
  }

  /* media */
  @include min-width(768px) {
    .MuiPaper-root {
      padding: 40px;
    }
    &__body {
      min-width: 300px;
    }
  }
  @include max-width(767px) {
    .MuiPaper-root {
      padding: 15px;
      max-width: 330px;
      width: 100%;
    }
  }
}


