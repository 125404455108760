.badge {
  --badge-height: 22px;

  font-size: 12px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  padding-inline: 12px;
  border-width: 1px;
  border-style: solid;
  border-radius: calc(var(--badge-height) / 2);
}