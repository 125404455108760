@use "variables" as var;
@use "mixins" as mixin;
@use "animations";

/*
  1. layout
  2. logo
  3. Ava styles
  4. home avatar
  5. loader styles
  6. location icon + text
  7. page top (navigation, title)
  8. desktop title for header with absolute
  9. rules events on add-edit events pages
*/

// fonts
body {
  font-family: "Montserrat", Arial, sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// 1. layout
#root {
  @include mixin.d-flex($direction: column);
  height: 100%; // it needs for a scroll
  .main-content {
    @include mixin.d-flex($direction: column);
    flex: 1 1 auto;
    &.preview {
      overflow-y: hidden;
    }
    // media
    @include mixin.max-width(var.$mobile-res) {
      &:not(.guest_page, .not_found, .privacy_policy--landing) {
        // padding-top: 57px;
      }
    }
    @include mixin.min-width(var.$tablet-res) {
      &:not(
          .guest_page,
          .privacy_policy,
          .not_found,
          .preview,
          .registration-global,
          .welcome-page
        ) {
        overflow-y: hidden;
      }
    }
    // @media (max-width: 767px) {
    // margin-top: 50px;
    // }
  }
  .main-content > :first-child:not(.registration-global__wrapper) {
    flex: 1;
    > .container {
      @include mixin.min-width(var.$tablet-res) {
        overflow-y: auto;
      }
      @include mixin.min-width(1201px) {
        @include mixin.scroll-bar(3px, var.$white_dark, 20px);
      }
    }
    // media
    @include mixin.min-width(var.$tablet-res) {
      &:not(
          div[class*="not_found"],
          div[class*="carousel"],
          .preview,
          .guest_page
        ) {
        @include mixin.d-flex();
        position: relative;
        padding-top: 68px;
        padding-right: 30px;
        grid-column-gap: 30px;
        overflow-y: hidden;
      }
      &:not(
          div[class*="not_found"],
          div[class*="carousel"],
          .preview,
          .guest_page,
          .olink-add,
          .olink-premium
        ) {
        margin-left: 255px;
        padding-left: 30px;
      }
      &:not(.preview, .olink-add, .personalization, .create-qr) > .container {
        padding: 30px;
      }
    }
    @include mixin.max-width(var.$mobile-res) {
      &:not(.home, .personalization, .create-qr) {
        padding-bottom: 30px;
      }
      &:not(.home) {
        padding-top: 20px;
      }
    }
  }
}

// 2.logo
.logoHome {
  display: block;
  width: 100%;
  flex-shrink: 0;
  margin-inline: auto;
  margin-top: auto;
  padding-top: 20px;
  &:not(&_rightBar) {
    @include mixin.adaptiv-value("max-width", 210, 120, 1);
  }
  &_rightBar {
    max-width: 130px;
  }
  &.preview {
    animation: scaleUp var.$transition--500 forwards;
    height: auto;
  }
}

// 3. Ava styles
.MuiAvatar-root {
  background-color: var.$white_dark !important;
  //border: 1px solid white;
  .MuiSvgIcon-root {
    width: 65%;
    height: 65%;
    fill: transparent;
    stroke: var.$white;
    stroke-width: 0.7px;
  }
}

// 4. home avatar
.avatar {
  display: block;
  text-align: center;

  & > div {
    margin-inline: auto !important;
    height: 128px;
    width: 128px;
  }
}

// 5. loader styles
.loader {
  @include mixin.d-flex($direction: column, $justifyContent: center);
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var.$gray_ligh_2;
  z-index: 101;
  visibility: hidden;
  transition: opacity 1s cubic-bezier(0.4, 0, 0.2, 1),
    visibility 1s cubic-bezier(0.4, 0, 0.2, 1);
  &_active {
    visibility: visible;
  }
  figure {
    margin: 0 auto;
    max-width: 550px;
    padding: 0 93px;
  }
}

// 6. location icon + text
.location {
  @include mixin.d-flex(center, center);
  grid-column-gap: 5px;
}

// 7. page top (navigation, title)
.page_top {
  @include mixin.adaptiv-value("margin-bottom", 30, 20, 1);
  @include mixin.min-width(var.$tablet-res) {
    @include mixin.d-flex(space-between, center);
  }
  @include mixin.max-width(var.$mobile-res) {
    display: grid;
    grid-template-columns: 28px 1fr 28px;
    align-items: center;
    h2 {
      font-size: 1.375rem; // 22px
      line-height: 1.27; //28px
      grid-column: 2/3;
    }
  }
}

// 8. desktop title for header with absolute
.page-title {
  position: absolute;
  left: 0;
  z-index: 101;
  transform: translateX(30px);
  top: 20px;
  pointer-events: none;
  @include mixin.min-width(1201px) {
    width: calc(100% - 444px);
  }
  @media screen and (min-width: 768px) and (max-width: 1200px) {
    width: calc(100% - 60px);
  }
  h2 {
    font-family: Gilroy, sans-serif;
  }
}

// 9. rules events on add-edit events pages
.eventsRules {
  @include mixin.min-width(1201px) {
    max-width: 677px;
    width: 100%;
    margin-inline: auto;
  }

  &__formGroup {
    @include mixin.adaptiv-value("padding-left", 15, 10, 1);
    @include mixin.adaptiv-value("padding-right", 15, 10, 1);
    @include mixin.min-width(var.$tablet-res) {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(315px, 1fr));
      grid-column-gap: 15px;
    }
  }

  &__row {
    @include mixin.min-width(1400px) {
      max-width: 675px;
      width: 100%;
      align-self: center;
    }
  }
}

// notidication badge
.notification-badge {
  &_white .MuiBadge-badge {
    border: 2px solid white;
  }
  &_black .MuiBadge-badge {
    border: 2px solid var.$black;
  }
  .MuiBadge-badge {
    background-color: var.$error;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    box-sizing: content-box;
  }
}
.swiper .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #969696;
  width: 24px;
  border-radius: 18px;
}
.swiper-button-prev,
.swiper-button-next {
  display: none !important;
}

img {
  image-rendering: crisp-edges;
  image-rendering: -webkit-optimize-contrast;
}
