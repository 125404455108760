@use '../../../assets/sass/variables' as var;
@use '../../../assets/sass/mixins';
@use '../../../assets/sass/common-elements/buttons';
@use '../../../assets/sass/common-elements/cards';
@use '../../../assets/sass/common-elements/forms';


// subscription styles

.subs_btn {
  grid-area: text;
  margin-bottom: 0;
  justify-content: center;
  padding: 14px 10px;
  @include mixins.max-width(var.$tabletL-res) {
    margin-top: 20px;
  }
}

.subscription {
  grid-column: 1 / -1;
  grid-row: 1/-1;
  display: grid;
  grid-template-columns: 1fr 38px;
  align-items: center;
  column-gap: 6px;
  position: relative;
  margin-left: -20px;
  margin-right: -20px;

  &__main_btn {
    height: 100%;
    padding: 15px 0;
    background-color: transparent;
    font: 600 1.125rem/1.15 Gilroy, sans-serif;
    text-align: left;
  }

  &__input {
    margin-bottom: 0 !important;
  }

  &__btn {
    align-self: start;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 59px;
    background: transparent;
    border: 1px solid var.$orange;
    border-radius: 16px;
  }

  p[id] {
    position: absolute;
    bottom: -15px;
  }
}

// hidden olink URL

.olink_url {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  font-size: 0;
}

.name_grid {
  grid-row: 1/3;
}

.align_center {
  align-self: center !important;
}
