@use "../variables" as var;
@use "../mixins" as mixin;
@use "../typography" as *;

/* desktop right_bar menu (preview, qr code, event helper) */
.right_bar {
  @include mixin.d-flex(false, false, column);
  max-width: 354px;
  width: 100%;
  max-height: 776px;
  height: 82vh;
  height: 82dvh;
  align-self: self-start;
  margin-top: -68px;
  background-color: var.$white;
  box-shadow: 0 2px 10px rgba(88, 88, 88, 0.1),
    0 4px 6px -2px rgba(88, 88, 88, 0.1);
  border-radius: 0 0 18px 18px;
  overflow: hidden;
  &__container {
    @include mixin.d-flex(false, false, column);
    flex: 1;
  }
  /* for desktop right preview window */
  &_preview-window .right_bar__container {
    margin: 16px;
    border: 8px solid var.$black;
    border-radius: 32px;
  }
  /* for desktop event right window */
  &_event-window .right_bar__container {
    align-items: center;
    justify-content: center;
    margin: 20px 20px 20px 20px;
  }
  /* for both preview and event window */
  &:where(&_preview-window, &_event-window) .right_bar__container {
    overflow: auto;
    @include mixin.scroll-bar(3px, var.$white_dark, 20px);
  }

  /* for qr code desktop preview */
  &.qr-code-preview {
    .right_bar__container {
      padding: 16px 20px;
      overflow: auto;
    }
    .qr_code {
      max-width: clamp(200px, 35vh, 316px);
      width: 100%;
      padding: clamp(20px, 3.5vh, 32px);
      margin: auto;
      //border: 1px solid var.$gray_dark;
      border-radius: var.$radius_s;
    }
  }

  // smart redirects preview
  &_event-window {
    .right_bar__container {
      justify-content: unset;
      padding-right: 5px;
    }

    .events-tips-list {
      counter-reset: tip-number;
      padding: 5px 10px 0 5px;
      > :not(:last-child) {
        margin-bottom: 26px;
      }
    }

    .events-tips-list .tips-list-title {
      padding-left: 8px;

      &:before {
        counter-increment: tip-number;
        content: counter(tip-number, decimal-leading-zero);
        margin-right: 13px;
        @extend .h4;
        font-weight: 700;
      }
    }
  }

  > h4 {
    padding: 20px;
    border-bottom: 1px solid var.$white_dark;
  }

  &__btn-wrapper {
    padding: 6px 16px 16px;
  }

  /* media */
  @include mixin.max-width(var.$tabletL-res) {
    display: none;
  }
}

.preview-bar {
  max-width: 354px;
  width: 100%;
  max-height: 776px;
  height: 82vh;
  height: 82dvh;
}
