@use '../variables' as var;
@use "../mixins" as mixin;

.popup {
  .MuiPaper-root {
    margin: 20px;
    text-align: center;
    border-radius: 16px;
    width: 100%;
    @include mixin.min-width(var.$tablet-res) {
      max-width: 738px;
      padding: 40px;
    }
    @include mixin.max-width(var.$mobile-res) {
      max-width: 335px;
    }
    .MuiDialogContent-root {
      margin-bottom: 40px;
      @include mixin.min-width(var.$tablet-res) {
        display: grid;
      }
    }

    .dialog-image {
      @include mixin.adaptiv-value("margin-bottom", 45, 27, 1);
      @include mixin.min-width(var.$tablet-res) {
        grid-row: 1;
      }
      img {
        margin: 0 auto;
      }
    }

    h2 {
      font: 700 1.375rem/1.27 'Gilroy', sans-serif;
      letter-spacing: 0.87px;
      @include mixin.adaptiv-value("font-size", 26, 22, 1);
      @include mixin.min-width(var.$tablet-res) {
        padding: 0;
        margin-bottom: 20px;
      }
      @include mixin.max-width(var.$mobile-res) {
        padding: 16px 15px 10px;
      }
    }

    p {
      font-family: 'Gilroy', sans-serif;
      color: #969696;
      padding: 0 15px;
    }

    span[role='separator'] {
      display: none;
    }

    .MuiDialogActions-root {
      @include mixin.min-width(var.$tablet-res) {
        @include mixin.d-flex();
        grid-column-gap: 32px;
        padding: 0 38px;
      }
      @include mixin.max-width(var.$mobile-res) {
        display: block;
      }
      button {
        @include mixin.max-width(var.$mobile-res) {
          padding: 18px 0;
          border-radius: 0 !important;
          background-color: transparent !important;
          box-shadow: none !important;
        }
        &:first-child {
          @include mixin.max-width(var.$mobile-res) {
            color: #fc7b52;
            border-top: 1px solid #eaeaea;
            border-bottom: 1px solid #eaeaea;
          }
          @include mixin.min-width(var.$tablet-res) {
            order: 2;
          }
        }
        &:last-child {
          @include mixin.max-width(var.$mobile-res) {
           border: 0;
            color: var.$black;
          }
        }

      }
    }
  }
}