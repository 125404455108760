.share-social {
  .MuiDialog-container {
    align-items: flex-end !important;
  }
  .MuiDialog-paper {
    border-radius: 0 !important;
    margin: 0 !important;
    max-width: none !important;
  }
}
