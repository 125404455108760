@use "src/assets/sass/variables" as var;
@use '../mixins';

.socials-container {
  display: grid;
  grid-auto-flow: column;
  gap: 30px;
  max-width: min-content;
  padding: 0 30px;

  .MuiIconButton-root {
    width: 63px;
    height: 63px;
    background-color: transparent;
    border: none;
    padding: 0;

    &:hover {
      border-radius: var.$radius_s;
    }
  }
  @media screen and (max-width: 350px) {
    gap: 5px;
  }
}
