@use "../../../assets/sass/variables.scss" as var;
@use "../../../assets/sass/mixins" as mixin;

.guest_page {
  @include mixin.d-flex();
  .container {
    @include mixin.min-width(var.$tablet-res) {
      display: grid;
      grid-template-columns: 1fr 331px;
      grid-template-rows: max-content;
    }
    @include mixin.max-width(var.$mobile-res) {
      padding-top: 30px;
      padding-bottom: 30px;
    }
    @include mixin.min-width(var.$tablet-res) {
      align-items: flex-start;
      justify-content: center;
      grid-column-gap: 34px;
    }
  }
  &__col {
    @include mixin.min-width(var.$tablet-res) {
      padding: 30px 38px 40px;
      max-width: 364px;
      width: 100%;
    }
  }
  figure {
    @include mixin.min-width(var.$tablet-res) {
      align-self: flex-end;
    }
  }
}

.merged_cells {
  display: flex;
  grid-column: 1/3;
  width: 100%;
  justify-content: center;
}

.grid-disabled {
  grid-template-columns: 0;
}
