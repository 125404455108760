@use "mixins";
@use "variables" as var;

.container {
  max-width: 1260px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  //height: 100%;

  position: relative;
  @media (max-width: 350px) {
    padding-left: 15px;
    padding-right: 15px;
  }
  &_fluid {
    @include mixins.min-width(768px) {
      max-width: unset;
      padding: 0;
    }
  }
  &_xs {
    max-width: 793px;
  }
}

.container-inner {
  &:not(&--xl) {
    padding: 0 15px;
  }
  &--xl {
    padding: 0 30px;
  }
}

// layout

.p-relative {
  position: relative;
}

.d-block {
  display: block;
}
.d-flex {
  display: flex;
}
.block-right {
  margin-left: auto;
}
.block-center {
  margin-inline: auto;
}

.grid-column {
  display: grid;
  grid-auto-flow: column;
}

/* flex */

.flex-center-center {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-around {
  justify-content: space-around;
}
.align-items-center {
  align-items: center;
}
.align-self-center {
  align-self: center;
}
.align-self-start {
  align-self: start;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-col {
  flex-direction: column;
}
.flex-1 {
  flex: 1;
}
.flex-shrink-0 {
  flex-shrink: 0;
}

/* gaps */

.gap-8 {
  gap: 8px;
}

.gap-15 {
  grid-gap: 15px;
}
.gap-20 {
  grid-gap: 20px;
}
.gap-25 {
  grid-gap: 25px;
}
.gap-30 {
  grid-gap: 30px;
}
.gap-35 {
  grid-gap: 35px;
}

.c-gap-5 {
  grid-column-gap: 5px;
}
.c-gap-10 {
  grid-column-gap: 10px;
}
.c-gap-15 {
  grid-column-gap: 15px;
}
.c-gap-20 {
  grid-column-gap: 20px;
}
.c-gap-25 {
  grid-column-gap: 25px;
}
.c-gap-30 {
  grid-column-gap: 30px;
}

/* margins */

.mb-5 {
  margin-bottom: 5px;
}
.mb-8 {
  margin-bottom: 8px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-12 {
  margin-bottom: 12px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-32 {
  margin-bottom: 32px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-55 {
  margin-bottom: 55px;
}

.mt-5 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-32 {
  margin-top: 32px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-55 {
  margin-top: 55px;
}

.mr-10 {
  margin-right: 10px;
}
.ml-mr-5 {
  margin: 0 5px;
}

/* typography */

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-uppercase {
  text-transform: uppercase;
}

// transitions

.transition--main {
  transition: var.$transitionGlobal;
}
.transition-500 {
  transition: 500ms;
}

// hide
.hide {
  display: none !important;
  visibility: hidden;
  position: absolute;
}
.disable {
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px !important;
}
.screen-reader-hide {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  touch-action: none;
}

// box-shadow

.shadow {
  box-shadow: 0px 6px 20px -2px rgba(88, 88, 88, 0.16),
    0px 5px 12px -2px rgba(88, 88, 88, 0.1);
}

.border-premium {
  --x: 30%;
  --y: 40%;
  --background: white;

  border: 1px solid transparent !important;
  background: linear-gradient(var(--background), var(--background)) padding-box,
    radial-gradient(farthest-corner at var(--x) var(--y), #ce1ff6, #575fee)
      border-box;
}
