@use "../../../assets/sass/variables" as var;
@use "../../../assets/sass/mixins";
@use "../../../assets/sass/common-elements/buttons";
@use "../../../assets/sass/common-elements/cards";
@use "../../../assets/sass/common-elements/forms";

// delete after release
.soon {
  pointer-events: none;
  user-select: none;
  &:before {
    display: none !important;
  }
  span:not(:last-of-type), img{
    opacity: .4;
  }
  &__text {
    position: absolute;
    right: 9px;
    top: 28px;
  }
}
/* ===== */

.acc_settings {
  .container {
    height: 100%;
  }
  &__wrapper {
    @include mixins.d-flex(false, false, column);
    flex: 1;
    @include mixins.min-width(var.$tablet-res) {
      max-width: 546px;
      width: 100%;
      align-self: center;
    }
  }

  .card_section {
    @extend .card;
    display: flex;
    flex-direction: column;
    padding: 15px;
    &:last-of-type {
      margin-bottom: 20px;
    }
    &__link {
      position: relative;
      display: grid;
      grid-template-columns: 48px 1fr;
      grid-auto-rows: auto;
      column-gap: 5px;
      align-items: center;
      justify-content: start;
      @include mixins.hover-supported {
        &:before {
          filter: brightness(1);
        }
        .card_section__title {
          background-color: var.$hover_main;
        }
      }
      &:before {
        @include mixins.chevron(absolute, 9px, $colorBlack: true);
        transition: var.$transitionGlobal;
      }
    }
    // divider
    li:not(:first-of-type) .card_section__link {
      &:before {
        top: 30px;
      }
      &:after {
        @include mixins.divider(1px, calc(100% - 10px), $color: var.$white_dark);
        position: static;
        margin: 6px 0 6px 10px;
        grid-column: 2/-1;
        grid-row: 1/2;
      }
    }

    &__icon {
      grid-row: 2/2;
      width: 48px;
      height: 48px;
      background: var.$orange_light_2;
      padding: 12px;
      border-radius: var.$radius_xs;
    }

    &__title {
      padding: 13px 10px;
      justify-self: start;
      grid-row: 2/2;
      font: 600 1.125rem/1.22 "Gilroy", sans-serif;
      color: var.$black;
      text-align: left;
      width: 100%;
      border-radius: var.$radius_s;
      transition: var.$transitionGlobal;
    }
  }
}
