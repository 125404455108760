@use '../../../assets/sass/variables' as var;
@use '../../../assets/sass/mixins';

.tooltip {
  position: relative;
  @include mixins.max-width(var.$mobile-res) {
    width: 28px;
    height: 28px;
  }
  &__btn {
    display: block;
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 50%;
    background: transparent;
    @include mixins.min-width(var.$tablet-res) {
      padding: 5px;
      border: 1px solid var.$gray_text-light;
      border-radius: var.$radius_xs;
    }
    @include mixins.hover-supported {
      background-color: var.$hoverMainDarker;
    }
  }
  svg {
    display: block;
    @include mixins.min-width(var.$tablet-res) {
      circle, ellipse {
        stroke: var.$gray_dark;
      }
      path {
        fill: var.$gray_dark;
      }
    }
  }

  &__content {
    position: absolute;
    z-index: 10;
    @include mixins.adaptiv-value("right", 19, 13, 1);
    @include mixins.adaptiv-value("top", 55, 45, 1);
    max-width: 800px;
    user-select: none;
    opacity: 0;
    visibility: hidden;
    box-shadow: 0 12px 40px rgba(46, 46, 46, 0.28);
    background: #ffffff;
    border: 1px solid #fdbda8;
    border-radius: 10px;
    padding: 15px 15px 20px;
    transition: 300ms ease;
    @include mixins.min-width(var.$tablet-res) {
      width: 40vw;
    }
    @include mixins.max-width(var.$mobile-res) {
      width: calc(100vw - 84px);
    }
    &::after {
      content: '';
      position: absolute;
      top: -6px;
      right: -1px;
      width: 15px;
      height: 20px;
      background: white;
      border-top: 1px solid #fdbda8;
      border-right: 1px solid #fdbda8;
      transform: skew(0, -35deg);
    }
  }
}

/* active state for mobiles and focus-visible */
.tooltip__btn{
  @media (pointer: coarse) {
    &:hover {
      circle {
        stroke: #fdbda8;
        fill: #fdbda8;
      }
      ellipse {
        stroke: white;
        fill: white;
      }
      path {
        fill: white;
      }
    }
  }
}

.tooltip:hover .tooltip__content,
.tooltip__btn:focus-visible + .tooltip__content{
  opacity: 1;
  visibility: visible;
}


.MuiTooltip-popper .MuiTooltip-tooltip {
  font: 400 1rem/1.375'Montserrat', Arial, sans-serif;
  box-shadow: 0 12px 40px rgba(46, 46, 46, 0.28);
  border: 1px solid #fdbda8;
  border-radius: 10px;
  padding: 15px 15px 20px;
  background-color: white;
  color: var.$gray_dark;
}
.MuiTooltip-arrow {
  color: white !important;
  &:before {
    border: 1px solid #fdbda8;
  }
}
