@use './templates_mixin' as *;
@use './templates_colors' as *;
@use "../variables" as var;

$icon-light: rgba(
  $color: #ffffff,
  $alpha: 0.44,
);
$icon-dark: rgba(
  $color: #2e2e2e,
  $alpha: 0.44,
);
// themes names: default, orange, dark, white, black, tree, sky, flat, neomorphism, glow, glass
.home.default {
  @include clr-scheme(
    $default,
    //main bg,
    false,
    //bg blend mode
    $black,
    //name
    $gray,
    //interest
    $black,
    //location
    transparent,
    //add bg clr
    $orange,
    // add bg clr hover
    $orangeLight,
    // add brdr clr
    $orange,
    // add brdr clr hover
    $orange,
    // add text clr
    $white,
    // add text clr hover
    $black,
    // cards bg clr
    ( 0px 6px 20px -2px rgba(88, 88, 88, 0.16),
      0px 5px 12px -2px rgba(88, 88, 88, 0.1)
    ),
    //box-shadow
    $icon-light,
    //social icons clr
    $white,
    //cards header clr
    $default,
    //cards text clr
    true,
    // border radius
    false,
    // border color
    $gray,
    // divider clr
    $gray_2,
    // cards icons clr
    $gray_4,
    //checkbox text clr
    $orange,   //checkbox bg color checked
    $gray,    //checkbox bg color unchecked
    $black,  //checkbox thumb bg color checked
    $gray_dark, //checkbox thumb bg color unchecked
    $gray_dark, //checkbox border color unchecked
    rgba($black, 0.54),
    //statistic widget bg
    false,
    // font-family, e.g. ('Comic Sans MS', cursive),
    $gray,
    // media type bg
    $black,
    // media type svg color
    false, // logo circle color
    false, // logo text color
    $orange, // add img input label text color
    $orangeLight, // add img input label border color
    $gray_4,  //add img input icon svg color
    false, // card edit form label color
    true, // card edit form input bg color
    var.$white, // card edit form input color
    var.$gray_dark_2 // card edit form input border color
  );
}

.home.orange {
  @include clr-scheme(
    $orange,
    //main bg,
    false,
    //bg blend mode
    $white,
    //name
    $default,
    //interest
    $white,
    //location
    $orange,
    //add bg clr
    transparent,
    //add bg clr hover
    $white,
    // add brdr clr
    $gray,
    // add brdr clr hover
    $white,
    // add text clr
    $gray,
    // add text clr hover
    $white,
    // cards bg clr
    ( 0px 2px 10px rgba(88, 88, 88, 0.1),
      0px 4px 6px -2px rgba(88, 88, 88, 0.1)
    ),
    //box-shadow
    $icon-dark,
    //social icons clr
    $black,
    //cards header clr
    $gray,
    //cards text clr
    true,
    // border radius
    false,
    // border color
    false,
    // divider clr
    false,
    // cards icons clr
    $gray,
    //checkbox text clr
    false,   //checkbox bg color checked
    false,   //checkbox bg color unchecked
    false,  //checkbox thumb bg color checked
    false, //checkbox thumb bg color unchecked
    false, //checkbox border color unchecked
    rgba($gray, 0.14),
    //statistic widget bg
    false,
    // font-family
    $gray_2,
    // media type bg
    $white,
    // media type svg color
    white, // logo circle color
    white,  // logo text color
    false, // add img input label text color
    false, // add img input label border color
    var.$orange //add img input icon svg color
  );

  // color of scroll at home page
  > .container {
    scrollbar-color: var.$black !important;
    &::-webkit-scrollbar-thumb {
      background-color: var.$black !important;
    }
  }
}

.home.dark {
  @include clr-scheme(
    $dark,
    //main bg,
    false,
    //bg blend mode
    $white,
    //name
    $default,
    //interest
    $white,
    //location
    $dark,
    //add bg clr
    transparent,
    //add bg clr hover
    $white,
    // add brdr clr
    $gray,
    // add brdr clr hover
    $white,
    // add text clr
    $gray,
    // add text clr hover
    $white,
    // cards bg clr
    (
      0px 2px 10px rgba(88, 88, 88, 0.1),
      0px 4px 6px -2px rgba(88, 88, 88, 0.1)
    ),
    //box-shadow
    $icon-dark,
    //social icons clr
    $black,
    //cards header clr
    $gray,
    //cards text clr
    0,
    // border radius
    false,
    // border color
    false,
    // divider clr
    false,
    // cards icons clr
    $gray,
    //checkbox text clr
    false,   //checkbox bg color checked
    false,   //checkbox bg color unchecked
    false,  //checkbox thumb bg color checked
    false, //checkbox thumb bg color unchecked
    false, //checkbox border color unchecked
    rgba($gray_2, 0.14),
    //statistic widget bg
    false,
    // font-family
    $gray_2,
    // media type bg
    $white,
    // media type svg color
    false, //logo circle color
    white,
    // logo text color
    false, // add img input label text color
    $gray_2, // add img input label border color
    $gray_2 //add img input icon svg color
  );
}

.home.white {
  @include clr-scheme(
    $white,
    //main bg,
    false,
    //bg blend mode
    $black,
    //name
    $gray,
    //interest
    $black,
    //location
    $default,
    //add bg clr
    $gray,
    //add bg clr hover
    $default,
    // add brdr clr
    $gray,
    // add brdr clr hover
    $black,
    // add text clr
    $default,
    // add text clr hover
    $default,
    // cards bg clr
    (0px 2px 10px rgba(88, 88, 88, 0.1),
    0px 4px 6px -2px rgba(88, 88, 88, 0.1)
    ),
    //box-shadow
    $icon-dark,
    //social icons clr
    $black,
    //cards header clr
    $gray,
    //cards text clr
    true,
    // border radius
    false,
    // border color
    $gray_2,
    // divider clr
    false,
    // cards icons clr
    $gray,
    //checkbox text clr
    false,   //checkbox bg color checked
    false,   //checkbox bg color unchecked
    false,  //checkbox thumb bg color checked
    false, //checkbox thumb bg color unchecked
    false, //checkbox border color unchecked
    rgba(black, 0.3),
    //statistic widget bg
    false,
    // font-family
    $gray_2,
    // media type bg
    $white,
    // media type svg color
    false, //logo circle color
    false, // logo text color
    false, // add img input label text color
    false, // add img input label border color
    var.$white_dark //add img input icon svg color
  );
}

.home.black {
  @include clr-scheme(
    $black,
    //main bg,
    false,
    //bg blend mode
    $white,
    //name
    $gray_2,
    //interest
    $white,
    //location
    transparent,
    //add bg clr
    transparent,
    //add bg clr hover
    $default,
    // add brdr clr
    $white,
    // add brdr clr hover
    $default,
    // add text clr
    false,
    // add text clr hover
    $gray_3,
    // cards bg clr
    (
      0px 6px 20px -2px rgba(88, 88, 88, 0.16),
      0px 5px 12px -2px rgba(88, 88, 88, 0.1)
    ),
    //box-shadow
    $icon-light,
    //social icons clr
    $white,
    //cards header clr
    $default,
    //cards text clr
    true,
    // border radius
    false,
    // border color
    $gray,
    // divider clr
    $gray_2,
    // cards icons clr
    $gray_4,
    //checkbox text clr
    $orange,   //checkbox bg color checked
    $gray,    //checkbox bg color unchecked
    $black,  //checkbox thumb bg color checked
    $gray_dark, //checkbox thumb bg color unchecked
    $gray_dark, //checkbox border color unchecked
    rgba($default, 0.14),
    //statistic widget bg
    false,
    // font-family
    $gray,
    // media type bg
    $black,
    // media type svg color
    false, // logo circle color
    white, // logo text color
    false, // add img input label text color
    $default, // add img input label border color
    $default, //add img input icon svg color
    $default, // card edit form label color
    true, // card edit form input bg color
    $white, // card edit form input color
    $default // card edit form input border color
  );

}

.home.tree {
  @include clr-scheme(
    $tree,
    //main bg,
    false,
    //bg blend mode
    $white,
    //name
    $white,
    //interest
    $white,
    //location
    transparent,
    //add bg clr
    transparent,
    //add bg clr hover
    $default,
    // add brdr clr
    $gray,
    // add brdr clr hover
    $default,
    // add text clr
    $gray,
    // add text clr hover
    $tree,
    // cards bg clr
    0px 4px 6px -2px rgba(88, 88, 88, 0.1),
    //box-shadows
    $icon-light,
    //social icons clr
    $white,
    //cards header clr
    $default,
    //cards text clr
    0,
    // border radius
    $white,
    // border color
    false,
    // divider clr
    $white,
    // cards icons clr
    $white,
    //checkbox text clr
    false,   //checkbox bg color checked
    false,   //checkbox bg color unchecked
    false,  //checkbox thumb bg color checked
    false, //checkbox thumb bg color unchecked
    false, //checkbox border color unchecked
    rgba($white, 0.14),
    //statistic widget bg
    false,
    // font-family
    $tree,
    // media type bg
    $white,
    // media type svg color
    false, //logo circle color
    white, // logo text color
    false, // add img input label text color
    $default, // add img input label border color
    $default, //add img input icon svg color
    var.$gray_ligh_2, // card edit form label color
    true, // card edit form input bg color
    var.$white, // card edit form input color
    false// card edit form input border color
  );
}

.home.sky {
  @include clr-scheme(
    $sky,
    //main bg,
    false,
    //bg blend mode
    $pink,
    //name
    $pink,
    //interest
    $pink,
    //location
    transparent,
    //add bg clr
    $pink,
    //add bg clr hover
    $pink,
    // add brdr clr
    $black,
    // add brdr clr hover
    $pink,
    // add text clr
    $black,
    // add text clr hover
    $sky,
    // cards bg clr
    false,
    //box-shadow
    $icon-light,
    //social icons clr
    $pink,
    //cards header clr
    $pink,
    //cards text clr
    true,
    // border radius
    $pink,
    // border color
    $pink,
    // divider clr
    $pink,
    // cards icons clr
    $pink,
    //checkbox text clr
    false,   //checkbox bg color checked
    false,   //checkbox bg color unchecked
    false,  //checkbox thumb bg color checked
    false, //checkbox thumb bg color unchecked
    false, //checkbox border color unchecked
    rgba($white, 0.14),
    //statistic widget bg
    false,
    // font-family
    $sky,
    // media type bg
    $pink,
    // media type svg color
    false, //logo circle color
    white, // logo text color
    false, // add img input label text color
    $pink, // add img input label border color
    $pink, //add img input icon svg color
    $pink, // card edit form label color
    true, // card edit form input bg color
    $pink, // card edit form input color
    $pink // card edit form input border color
  );
}

.home.flat {
  @include clr-scheme(
    $flat,
    //main bg,
    false,
    //bg blend mode
    $green,
    //name
    $green,
    //interest
    $green,
    //location
    transparent,
    //add bg clr
    $green,
    //add bg clr hover
    $green,
    // add brdr clr
    $green,
    // add brdr clr hover
    $green,
    // add text clr
    var.$white,
    // add text clr hover
    $flat,
    // cards bg clr
    0px 4px 6px -2px rgba(88, 88, 88, 0.1),
    //box-shadow
    $icon-dark,
    //social icons clr
    $green,
    //cards header clr
    $green,
    //cards text clr
    0,
    // border radius
    $green,
    // border color
    $green,
    // divider clr
    $green,
    // cards icons clr
    $green,
    //checkbox text clr
    false,   //checkbox bg color checked
    false,   //checkbox bg color unchecked
    false,  //checkbox thumb bg color checked
    false, //checkbox thumb bg color unchecked
    false, //checkbox border color unchecked
    rgba($gray, 0.14),
    //statistic widget bg
    false,
    // font-family
    $flat,
    // media type bg
    $green,
    // media type svg color
    false, //logo circle color
    $green, // logo text color
    false, // add img input label text color
    $green, // add img input label border color
    var.$white, //add img input icon svg color
    $green, // card edit form label color
    true, // card edit form input bg color
    $green, // card edit form input color
    $green// card edit form input border color
  );
}

.home.neo {
  @include clr-scheme(
    $neoGradient,
    //main bg,
    true,
    //bg blend mode
    $black,
    //name
    $gray,
    //interest
    $black,
    //location
    $neoGradient,
    //add bg clr
    $neoGradient,
    //add bg clr hover
    rgba(255, 255, 255, 0.4),
    // add brdr clr
    $black,
    // add brdr clr hover
    $black,
    // add text clr
    $black,
    // add text clr hover
    $neoGradient,
    // cards bg clr
    (-5px -5px 12px #FAFBFF, 4px 4px 8px #A6ABBD),
    //box-shadow
    $icon-dark,
    //social icons clr
    $black,
    //cards header clr
    $gray,
    //cards text clr
    true,
    // border radius
    rgba(255, 255, 255, 0.4),
    // border color
    $white,
    // divider clr
    false,
    // cards icons clr
    $gray,
    //checkbox text clr
    false,   //checkbox bg color checked
    false,   //checkbox bg color unchecked
    false,  //checkbox thumb bg color checked
    false, //checkbox thumb bg color unchecked
    false, //checkbox border color unchecked
    rgba($gray, 0.14),
    //statistic widget bg
    false,
    // font-family
    $gray_2,
    // media type bg
    $black,
    // media type svg color
    false, //logo circle color
    false, // logo text color
    false, // add img input label text color
    false, // add img input label border color
    false, //add img input icon svg color
  );
}

.home.glow {
  @include clr-scheme(
    $glow,
    //main bg,
    false,
    //bg blend mode
    $white,
    //name
    $default,
    //interest
    $white,
    //location
    transparent,
    //add bg clr
    transparent,
    //add bg clr hover
    $default,
    // add brdr clr
    $orange,
    // add brdr clr hover
    $default,
    // add text clr
    $default,
    // add text clr hover
    $trans_dark,
    // cards bg clr
    (
      0px 2px 15px -3px rgba(250, 250, 250, 0.15),
      0px 2px 6px -4px rgba(250, 250, 250, 0.1)
    ),
    //box-shadow
    $icon-dark,
    //social icons clr
    $white,
    //cards header clr
    $default,
    //cards text clr
    true,
    // border radius
    false,
    // border color
    $gray_4,
    // divider clr
    $gray_2,
    // cards icons clr
    $gray_4,
    //checkbox text clr
    false,   //checkbox bg color checked
    false,   //checkbox bg color unchecked
    false,  //checkbox thumb bg color checked
    false, //checkbox thumb bg color unchecked
    false, //checkbox border color unchecked
    rgba($default, 0.14),
    //statistic widget bg
    false,
    // font-family
    $trans,
    // media type bg
    $black,
    // media type svg color
    false, //logo circle color
    white, // logo text color,
    false, // add img input label text color
    $default, // add img input label border color
    $default, //add img input icon svg color
    $white, // card edit form label color
    true, // card edit form input bg color
    $white, // card edit form input color
    $white// card edit form input border color
  );
}

.home.glass {
  @include clr-scheme(
    $glass,
    //main bg,
    false,
    //bg blend mode
    $white,
    //name
    $default,
    //interest
    $white,
    //location
    transparent,
    //add bg clr
    transparent,
    //add bg clr hover
    $white,
    // add brdr clr
    $orange,
    // add brdr clr hover
    $default,
    // add text clr
    $default,
    // add text clr hover
    $trans,
    // cards bg clr
    (
      0px 4px 6px -1px rgba(88, 88, 88, 0.1),
      0px 2px 4px -2px rgba(88, 88, 88, 0.1)
    ),
    //box-shadow
    $icon-dark,
    //social icons clr
    $white,
    //cards header clr
    $default,
    //cards text clr
    true,
    // border radius
    false,
    // border color
    false,
    // divider clr
    $gray_2,
    // cards icons clr
    $gray_2,
    //checkbox text clr
    false,   //checkbox bg color checked
    false,   //checkbox bg color unchecked
    false,  //checkbox thumb bg color checked
    false, //checkbox thumb bg color unchecked
    false, //checkbox border color unchecked
    rgba($black, 0.14),
    //statistic widget bg
    false,
    // font-family
    $trans,
    // media type bg
    $black,
    // media type svg color
    false, //logo circle color
    white, // logo text color
    false, // add img input label text color
    $default, // add img input label border color
    $default, //add img input icon svg color
    $white, // card edit form label color
    true, // card edit form input bg color
    $white, // card edit form input color
    $white// card edit form input border color
  );
}
