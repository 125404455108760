@use '../../../assets/sass/variables' as var;
@use '../../../assets/sass/mixins' as mixin;

$animationTime: 400ms;

$success__100: #cff5df;
$success__200: rgba(101, 191, 127, 0.64);
$success__300: #65bf7f;

$warning__100: #fbe8c7;
$warning__200: rgba(243, 180, 76, 0.64);
$warning__300: #f3b44c;

$error__100: #f6d7d0;
$error__200: rgba(217, 89, 58, 0.64);
$error__300: #E81515;



.snackbar {
  position: fixed;
  width: 100%;
  @include mixin.adaptiv-value('padding-left', 18, 10, 1);
  @include mixin.adaptiv-value('padding-right', 18, 10, 1);
  column-gap: 10px;
  transition: 300ms !important;
  @include mixin.min-width(1201px) {
    @include mixin.d-flex(false, center, row);
    right: 30px;
    max-width: 354px;
    bottom: 30px;
    border-radius: var.$radius_xs !important;
    padding-top: 14px;
    padding-bottom: 14px;
    animation: $animationTime slide-right ease;
  }
  @include mixin.max-width(var.$tabletL-res) {
    left: 0;
    right: 0;
    bottom: 0;
    border-top: 1px solid;
    display: grid;
    grid-template-columns: 25px 1fr 25px;
    align-items: center;
    padding-top: 4px;
    padding-bottom: 4px;
    animation: $animationTime slide-down ease;
  }
  @media screen and (min-width: var.$tablet-res) and (max-width: var.$tabletL-res){
    width: calc(100% - 255px);
    left: unset;
  }
  &:before {
    @include mixin.pseudo();
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
    @include mixin.min-width(1201px) {
      filter: brightness(0) invert(1);
    }
  }

  p {
    font: 400 0.875rem/1.3 "Montserrat", sans-serif;
    text-align: center;
    text-transform: capitalize;
    @include mixin.max-width(var.$tabletL-res) {
      color: var.$black;
    }
    @include mixin.min-width(1201px) {
      color: var.$white;
      font-weight: 500;
    }
  }
}

.snackbar__success, .snackbar__error, .snackbar__warning {
  @include mixin.max-width(var.$tabletL-res) {
    animation: $animationTime slide-up cubic-bezier(0.17,0.84,0.44,1);
  }
  @include mixin.min-width(1201px) {
    animation: $animationTime slide-left cubic-bezier(0.18,0.89,0.32,1.07);
  }
}

.snackbar__success {
  &:before {
    content: url("../../../assets/svg/snackIcons/success.svg");
  }
  @include mixin.max-width(var.$tabletL-res) {
    background-color: $success__100;
    border-color: $success__200;
  }
  @include mixin.min-width(1201px) {
    background-color: $success__300;
  }
}

.snackbar__warning {
  &:before {
    content: url("../../../assets/svg/snackIcons/attention.svg");
  }
  @include mixin.max-width(var.$tabletL-res) {
    background: $warning__100;
    border-color: $warning__200;
  }
  @include mixin.min-width(1201px) {
    background-color: $warning__300;
  }
}

.snackbar__error {
  &:before {
    content: url("../../../assets/svg/snackIcons/error.svg");
  }
  @include mixin.max-width(var.$tabletL-res) {
    background: $error__100;
    border-color: $error__200;
  }
  @include mixin.min-width(1201px) {
    background-color: $error__300;
  }
}

@keyframes slide-up {
  0% {
    bottom: -100%;
  }
  100% {
    bottom: 0;
  }
}
@keyframes slide-down {
  0% {
    bottom: 0;
  }
  100% {
    bottom: -100%;
  }
}

@keyframes slide-left {
  0% {
    right: -100%;
  }

  100% {
    right: 33px;
  }
}

@keyframes slide-right {
  0% {
    right: 33px;
  }

  100% {
    right: -100%;
  }
}
