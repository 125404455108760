@use '../../../assets/sass/variables' as var;
@use '../../../assets/sass/mixins' as mixin;

.not_found {
  .container {
    padding-top: 33px;
    padding-bottom: 20px;
    height: 100%;
    @include mixin.min-width(var.$tablet-res) {
      grid-row-gap: 30px;
    }
/*    button[class*='btn'] {
      margin-top: auto;
    }*/
  }
  &__logo {
    max-width: 114px;
  }
  &__content {
    @include mixin.min-width(var.$tablet-res) {
      max-width: 678px;
      margin-top: auto;
      margin-bottom: auto;
      padding: 40px;
    }
    @include mixin.max-width(var.$mobile-res) {
      @include mixin.d-flex(center, false, column);
      flex: 1;
    }
  }
  &__emoji {
    max-width: 287px;
    margin: 0 auto;
    img {
      @include mixin.adaptiv-value('margin-bottom', 45, 33, 1);
    }
    h1 {
      font-size: 32px;
      line-height: 1.44;
      letter-spacing: 0.87px;
    }
  }
  &__textContainer {
    @include mixin.max-width(var.$mobile-res) {
      max-width: 375px;
      margin: 0 auto;
    }
  }
  p:first-of-type {
    @include mixin.adaptiv-value('margin-bottom', 8, 15, 1);
  }
}

.main_content_home{
  padding-top: 0;
}


