@use "../variables" as var;
@use "../mixins";

// regular
.btn {
  display: block;
  margin-inline: auto;
  width: 100%;
  max-width: 500px;
  background: var.$orange;
  border-radius: var.$radius_s;
  border: 1px solid transparent;
  color: var.$white;
  font: 600 1.125rem/1.3333 "Gilroy", "Montserrat", sans-serif;
  letter-spacing: var.$lSpacing_xs;
  text-transform: capitalize;
  text-align: center;
  &:not(
      .btn_medium,
      .btn__fixed,
      .btn__gradient,
      .btn__premium,
      button[class*="share__btn"]
    ) {
    padding-inline: 10px;
    @include mixins.adaptiv-value("padding-block", 12, 19, 1);
  }
  &:not(.btn__outline, [disabled], .btn__fixed) {
    box-shadow: 0px 6px 20px -2px rgba(88, 88, 88, 0.16),
      0px 5px 12px -2px rgba(88, 88, 88, 0.1);
  }

  &:not(
      .btn__outline,
      .btn-gradient-outline,
      .btn__preview,
      .btn__gradient,
      .btn__premium,
      .btn__save,
      [disabled]
    ) {
    @include mixins.hover-supported {
      color: var.$white;
      background: var.$orangeHovered;
      border-color: var.$orangeHovered;
      text-decoration: none;
    }
  }
  &[disabled],
  &.MuiButton-root[disabled] {
    background: var.$gray;
    color: var.$white;
    cursor: default;
    @media (any-hover: hover) {
      &:hover {
        background: var.$gray;
        border-color: transparent;
      }
    }
  }

  &_medium {
    padding-inline: 10px;
    @include mixins.adaptiv-value("padding-block", 12, 14, 1);
  }
  &_desktop {
    @include mixins.min-width(var.$tablet-res) {
      max-width: 275px !important;
    }
    &_wide {
      @include mixins.min-width(var.$tablet-res) {
        max-width: 315px !important;
      }
    }
  }

  // outlined
  &__outline {
    @extend .btn;
    border-color: var.$orange_light_3;
    background: transparent;
    color: var.$orange;
    /*  @media (any-hover: hover) {
      &:hover {
        background-color: var.$gray_ligh_2;
      }
    }*/
    @include mixins.hover-supported {
      background-color: var.$gray_ligh_2;
    }
    &[disabled],
    &_disabled {
      background-color: transparent;
      pointer-events: none;
      color: var.$gray_text-light;
      border-color: var.$gray_text-light;
    }
    &.MuiButtonBase-root {
      @extend .btn__outline;
    }
  }

  // updated
  &--updated {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    background: var.$orange_light !important;
    border-color: var.$orange_light !important;
    color: var.$white !important;
    pointer-events: none;
    &::before {
      content: url("../../svg/updated.svg");
      width: 18px;
      height: 18px;
      margin-right: 11px;
    }
  }

  // gradiented
  &__gradient {
    @extend .btn;
    justify-content: center;
    padding: 11px 10px;
  }

  // delete btn (delete account,delete olink, wave rights)
  &__delete {
    font: 400 1.125rem/1 "Gilroy", sans-serif;
    align-self: self-end;
    margin-right: 5px;
    padding: 8px 12px;
    border-radius: var.$radius_s;
    @include mixins.max-width(var.$mobile-res) {
      margin-top: auto;
    }
    @include mixins.hover-supported {
      background-color: var.$warningHover;
    }
  }

  &.btn-gradient-outline {
    --x: 30%;
    --y: 40%;
    --background: white;

    border: 1px solid transparent !important;
    background: linear-gradient(var(--background), var(--background))
        padding-box,
      radial-gradient(farthest-corner at var(--x) var(--y), #ce1ff6, #575fee)
        border-box;
    box-shadow: none !important;
    transition: all 0.3s ease;

    > span {
      color: transparent;
      background: radial-gradient(
        96.23% 85.68% at 14.33% 14.32%,
        #ce1ff6 0%,
        #575fee 100%
      );
      background-clip: text;
    }

    &:hover {
      background: radial-gradient(
        96.23% 85.68% at 14.33% 14.32%,
        #ce1ff6 0%,
        #575fee 100%
      );

      > span {
        color: white;
        background: radial-gradient(
          96.23% 85.68% at 14.33% 14.32%,
          #ce1ff6 0%,
          #575fee 100%
        );
        background-clip: unset;
      }
    }
  }
}

.btn.MuiButtonBase-root {
  @extend .btn;
}

//disabled
.btn__disabled.MuiButtonBase-root {
  @extend .btn__disabled;
}

// premium btn
.btn__premium {
  @extend .btn;
  padding: 0 10px;
  &_small {
    font-size: mixins.rem(18);
    padding-block: 12px;
    border-radius: 12px;
    &:before {
      width: 32px;
      height: 32px;
      margin-right: 8px;
    }
  }
  &:not(&_small) {
    @include mixins.adaptiv-value("padding-block", 10, 15, 1);
    font-size: mixins.rem(22);
    &:before {
      width: clamp(2.5rem, -5vw + 5.3rem, 3rem); // 40px - 48px
      height: clamp(2.5rem, -5vw + 5.3rem, 3rem); // 40px - 48px
      margin-right: 12px;
    }
  }
  &:not(&--active) {
    @include mixins.d-flex(center, center);
    background-image: radial-gradient(
      96.23% 85.68% at 14.33% 14.32%,
      #ce1ff6 0%,
      #575fee 100%
    );
    &::before {
      content: "";
      background: url("../../svg/diamant.svg") center center/cover no-repeat;
      background-size: 100%;
    }
  }

  //active subscription
  &--active {
    border: 1px solid #ce1ff6 !important;
    background: radial-gradient(
        96.23% 85.68% at 14.33% 14.32%,
        rgba(206, 31, 246, 0.14) 0%,
        rgba(87, 95, 238, 0.14) 100%
      ),
      #ffffff;
    span {
      @extend %premium-color;
    }
  }
}

.btn__gradient,
.btn__premium {
  border: 0;
  background-size: 100%;
  @include mixins.hover-supported {
    background-size: 200%;
  }
}
.btn__gradient {
  background-image: radial-gradient(
    96.23% 85.68% at 14.33% 14.32%,
    #ce1ff6 0%,
    #575fee 100%
  );
}

// fixed
.btn__fixed {
  @extend .btn;
  display: inline-block;
  position: fixed;
  z-index: 100;
  bottom: var(--snackbar, clamp(20px, 2.5vw, 30px));
  font-size: 16px;
  line-height: 1.38;
  border-radius: var.$radius_l !important;
  min-width: 138px;
  margin: initial !important;
  padding-left: 24px;
  padding-right: 24px;
  @include mixins.adaptiv-value("padding-block", 14, 13, 1);
  box-shadow: 0px 12px 40px -6px rgba(46, 46, 46, 0.28);
  @include mixins.min-width(1201px) {
    right: 73px;
    max-width: 275px;
  }
  @include mixins.max-width(var.$tabletL-res) {
    width: max-content !important;
  }
  @media screen and (min-width: 768px) and (max-width: 1200px) {
    left: 285px;
  }
  @include mixins.max-width(var.$mobile-res) {
    left: 12px;
  }

  &.btn__save {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    img {
      margin-right: 10px;
    }
    &:not([disabled]) {
      @include mixins.hover-supported {
        background: var.$orangeHovered;
      }
    }
  }

  &.btn--updated {
    display: inline-flex !important;
    vertical-align: top;
    &::before {
      content: url("../../svg/updated--fixed.svg");
      width: 18px;
      height: 18px;
      margin-right: 11px;
    }
  }

  &--right {
    left: 100%;
  }
}

// preview button
.btn__preview {
  position: fixed;
  display: flex;
  align-items: center;
  bottom: var(--snackbar, 20px);
  background: var.$white;
  color: var.$black;
  text-transform: initial;
  left: unset;
  transition: bottom 400ms ease 300ms, right 400ms ease 300ms;
  @include mixins.min-width(var.$tablet-res) {
    right: 60px;
  }
  @include mixins.max-width(var.$mobile-res) {
    right: 12px;
  }
  img {
    margin-right: 8px;
  }
}

// for buttons with icons
.MuiListItemIcon-root {
  min-width: auto !important;
  display: block !important;
}
.icon {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  align-items: center;
  column-gap: 9px;
  &__center {
    justify-content: center;
  }
}

// btn`s navigate (arrow btn(back), add btn, create btn)
.btn_navigate {
  //background: none;
  @include mixins.min-width(var.$tablet-res) {
    @include mixins.d-flex(center, center);
    font: 600 1rem/1.38 "Gilroy", sans-serif;
    padding-top: 7px;
    padding-bottom: 7px;
    grid-column-gap: 5px;
    border: 1px solid;
    border-radius: var.$radius_xs;
  }
  &::before {
    display: block;
    @include mixins.min-width(var.$tablet-res) {
      width: 24px;
      height: 24px;
    }
  }
  @include mixins.hover-supported {
    background-color: var.$hoverMainDarker;
  }

  //arrow back navigation btn
  &_arrow {
    @include mixins.min-width(var.$tablet-res) {
      padding-left: 9px;
      padding-right: 20px;
      color: var.$gray_dark;
      border-color: var.$gray_text-light;
    }
    @include mixins.max-width(var.$mobile-res) {
      padding: 12px;
      font-size: 0;
      transform: rotate(-180deg);
    }
    &:before {
      content: url("../../../assets/svg/arrow-right.svg");
      @include mixins.min-width(var.$tablet-res) {
        transform: rotate(-180deg) translateY(3px);
      }
      @include mixins.max-width(var.$mobile-res) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  &_add,
  &_create {
    @include mixins.min-width(var.$tablet-res) {
      padding-right: 18px;
      padding-left: 10px;
      color: var.$orange;
      border-color: var.$orange_light_3;
      min-width: 110px;
    }
  }
  &_create:before {
    content: url("../../../assets/svg/pen--orange.svg");
  }
  &_add:before {
    content: url("../../../assets/svg/plus--orange.svg");
  }
}

// copy email, date picker btns
.btn_utility {
  @include mixins.d-flex(center, center);
  width: 48px;
  height: 48px;
  background: var.$white;
  border-radius: var.$radius_s;
  border: 1px solid transparent;
  box-shadow: 0px 2px 10px rgba(88, 88, 88, 0.1),
    0px 4px 6px -2px rgba(88, 88, 88, 0.1);
  @include mixins.hover-supported {
    border-color: var.$orange_light_3;
  }
}

.btn-as-link {
  font-size: 1rem;
  font-weight: 600;
  text-decoration: underline;
  margin: 0;
  padding: 0;
  background: none;
  border: 0 none;
}

//== TABS ==//

.tabs {
  max-width: 331px;
  width: 100%;
  align-self: center;
  border: 1px solid var.$white_dark;
  border-radius: var.$radius_xs;
  padding: 1px;
  background-color: var.$gray_ligh_2;
  margin-bottom: 30px;
  box-shadow: 0 2px 10px rgba(88, 88, 88, 0.1),
    0 4px 6px -2px rgba(88, 88, 88, 0.1);
  span[class*="MuiTabs-indicator"] {
    display: none;
  }

  .MuiTabs-root {
    min-height: unset;
  }
  .MuiTabs-flexContainer {
    align-items: center;
    position: relative;
  }

  &__list {
    button {
      position: relative;
      z-index: 1;
      flex: 1;
      padding: 0;
      font: 400 0.875rem/1 "Gilroy", sans-serif;
      text-transform: capitalize;
      color: var.$black;
      max-width: initial;
      min-width: initial;
      overflow: visible;
      transition: var.$transitionGlobal;
      @include mixins.adaptiv-value("min-height", 36, 40, 1);
      &:focus-visible {
        outline: auto;
      }
    }

    // animation
    & button:first-child {
      &::after {
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: var.$orange;
        border-radius: 6px;
        transition: var.$transitionGlobal ease;
      }

      &[aria-selected="false"] {
        &::after {
          transform: translateX(100%);
        }
      }
    }
    // end animation

    button[aria-selected="true"] {
      //font-size: 1rem;
      font-weight: 600;
      color: var.$white;
    }
  }
}

div.tab-panel {
  padding: 0;
}

//== END TABS ==//
