@use '../../../assets/sass/variables' as var;
@use '../../../assets/sass/mixins' as mixin;
@use '../../../assets/sass/common-elements/buttons';


.delegate {
  &__card {
    @include mixin.adaptiv-value("margin-bottom", 20, 30, 1);
    @include mixin.min-width(var.$tablet-res) {
      padding: 30px 30px 40px;
    }
  }
  &__card, .rights {
    max-width: 546px;
    width: 100%;
    align-self: center;
  }
  &__user {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto;
    column-gap: 15px;
    grid-template-areas:
      'icon name'
      'icon text';
  }

  &__ava {
    grid-area: icon;
    width: 64px !important;
    height: 64px !important;
    border: 1px solid var.$orange;
  }
  &__name {
    grid-area: name;
    align-self: end;
    margin-bottom: 5px;
  }
  &__text {
    grid-area: text;
    color: var.$gray_dark_2;
  }
  &__text_small {
    line-height: 1.5;
  }
}

.rights {
  padding-top: 20px;
  padding-bottom: 10px;
}
