@use '../variables' as var;
@use "../mixins" as mixin;

// delete account, delete olink, delegate rights styles
.deleteContent {
  @include mixin.min-width(var.$tablet-res) {
    max-width: 678px;
    padding: 40px;
  }
  @include mixin.max-width(var.$mobile-res) {
    @include mixin.d-flex(
            $justifyContent: center,
            $alignItems: false,
            $direction: column
    );
    flex: 1;
  }

  &__wrapper {
    @include mixin.adaptiv-value("max-width", 315, 375, 1);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  &__text {
    max-width: 414px;
    margin-left: auto;
    margin-right: auto;
  }

  figure {
    @include mixin.min-width(var.$tablet-res) {
      margin-top: 30px;
    }
  }
  .delete__answers {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
    button {
      @include mixin.adaptiv-value("border-radius", 8, 16, 1);
      padding-left: 20px;
      padding-right: 20px;
      min-width: 130px;
      width: fit-content;
      font-family: "Gilroy", sans-serif;
      background: transparent;
      &:first-of-type {
        padding-block: clamp(0.75rem, -2vw + 2rem, 1rem); // 12px - 16px
        font-size: mixin.rem(16);
        font-weight: 600;
        color: var.$black;
        justify-self: end;
        border: 1px solid;
        box-shadow:
                0px 2px 10px rgba(88, 88, 88, 0.1),
                0px 4px 6px -2px rgba(88, 88, 88, 0.1);
        @include mixin.min-width(var.$tablet-res) {
          border-color: var.$white_dark;
          @include mixin.hover-supported {
            background-color: var.$hover_main;
          }
        }
        @include mixin.max-width(var.$mobile-res) {
          border-color: var.$gray_text-light;
        }
      }
      &:last-of-type {
        font-size: 1.125rem;
        justify-self: start;
        padding-block: clamp(0.375rem, -5vw + 3.5rem, 1rem); // 6px - 16px
        @include mixin.min-width(var.$tablet-res) {
          border-radius: var.$radius_s;
        }
        &[disabled] {
          opacity: .5;
          pointer-events: none;
          user-select: none;
        }
        &:not([disabled]) {
          @include mixin.hover-supported {
            background-color: var.$warningHover;
          }
        }
      }
    }
  }
}
