@use '../../../../assets/sass/variables' as *;
@use '../../../../assets/sass/mixins' as mixin;
@use '../../../../assets/sass/common-elements/buttons';

.share {

  &__title {
    max-width: none;
    width: 100%;
    padding: 15px 30px 0 30px !important;
    display: grid;
    grid-template-columns: 24px 1fr 24px;
    column-gap: 5px;

/*    p {
      grid-column: 2/3;
      font-size: 1.375rem;
    }*/
    button {
      grid-column: 3/-1;
    }
  }

/*  &__code {
    width: 205px;
    height: 205px;
    margin: 0 auto 20px;
  }*/

  &__close {
    border-radius: 50%;
    @include mixin.hover-supported {
      background-color: $hoverMainDarker;
    }
  }

  &__content {
    padding: 15px 30px 30px !important;
    @media (max-width: 21.875em) {
      padding: 15px 15px 15px !important;
    }
  }

  &__row {
    @include mixin.d-flex(center);
    grid-gap: 10px;
    //margin-bottom: 30px;
    svg {
      display: block;
    }
  }

  button:not(.share__close) {
    @include mixin.hover-supported {
      opacity: .5;
    }
  }

/*  &__btn {
    @extend .btn__outline;
    @extend .icon;
    justify-content: center;
    gap: 0;
    padding: 16px 10px;
    font-size: 1rem;
    margin-bottom: 0;

    img {
      margin-right: 10px;
    }

    &:hover {
      color: $orange;
      background: inherit;
    }
  }*/

  .MuiDialog-container{
    border-radius: 0;
  }
}
