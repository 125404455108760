@use "variables" as var;
@use 'sass:math';

/*
  1. media queries
  2. hover supported devices
  3. pseudo element
  4. Flex
  5. function convert px to rem
  6. fluid params in px
  7. divider element
  8. statistic widget color circles
  9. input arrow
  10. scrollbar style mixin
  11. controls hover element
  12. default browsers outline for accessibility
*/

/* 1. media queries*/
@mixin max-width($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@mixin min-width($width) {
  @media (min-width: $width) {
    @content;
  }
}

@mixin max-height($height) {
  @media (max-height: $height) {
    @content;
  }
}

@mixin min-height($height) {
  @media (min-height: $height) {
    @content;
  }
}

/* 2. mixin for hover supported devices */
@mixin hover-supported {
  @media not all and (hover: none) {
    &:hover {
      @content;
    }
  }
}

/*  3. pseudo element */
@mixin pseudo($content: '', $position: false, $display: block) {
  content: $content;
  @if $position {position: $position};
  display: $display;
}

/* 4. Flex */
@mixin d-flex(
          $justifyContent: false,
          $alignItems: false,
          $direction: false,
          $wrap: false,) {
  display: flex;
  @if $justifyContent {justify-content: $justifyContent};
  @if $alignItems {align-items: $alignItems};
  @if $direction {flex-direction: $direction;}
  @if $wrap {flex-wrap: $wrap;}
}

/* 5. function convert px to rem */

@function rem($value) {
  $rootValue : 16; // set root value for font-size in px
  @return math.div($value, $rootValue) * 1rem;
}


/*  6. fluid params in px */

$maxWidthContainer: 1440; // container size + padding
$maxWidth: 1920; // size of design

@mixin adaptiv-value($property, $pcSize, $mobSize, $type) { $addSize: $pcSize - $mobSize; @if $type==1 {
  //only if less then container
  #{$property}: $pcSize + px; @media (max-width: #{$maxWidthContainer + px}) { #{$property}: calc(#{$mobSize + px} + #{$addSize} * ((100vw - 375px) / #{$maxWidthContainer - 375})); }
  }
  @else if $type==2 {
  //only if bigger then container
  #{$property}: $pcSize + px; @media (min-width: #{$maxWidth + px}) { #{$property}: calc(#{$mobSize + px} + #{$addSize} * ((100vw - 375px) / #{$maxWidth - 375})); }
  }
  @else {
  //always
  #{$property}: calc(#{$mobSize + px} + #{$addSize} * ((100vw - 375px) / #{$maxWidth - 375})); }
  }


// === fluid params END ==== //


/* 7. divider element */
@mixin divider(
          $height: height,
          $width: width,
          $left: false,
          $right: false,
          $color: false)
{
  @include pseudo($position: absolute);
  height: $height;
  width: $width;
  top: 0;
  @if $color {background-color: $color};
  @if $left {left: $left};
  @if $right {right: $right};
}

/* 8. statistic widget color circles  */
@mixin widget-circle(
          $height: height,
          $width: width,
          $color: background-color) {
  @include pseudo();
  height: $height;
  width: $width;
  background-color: $color;
  border-radius: 50%;
}


/*  9. input arrow  */
@mixin chevron (
            $position: false,
            $right: false,
            $top: false,
            $colorBlack: false,
            $transform: false) {
  content: url("../../assets/svg/arrow-right--orange.svg");
  display: block;
  @if $position {position: $position};
  width: 9px;
  height: 14px;
  @if $right {right: $right};
  @if $top {top: $top};
  @if $transform {transform: $transform}
  @if $colorBlack == true {
    filter: brightness(0);
  }
  transition: var.$transitionGlobal;
}

/* 10. scrollbar style mixin */
@mixin scroll-bar($width: false, $track-color: false, $m-top: false) {
  scrollbar-width: thin;
  scrollbar-color: var.$orange $track-color;
  &::-webkit-scrollbar {
    @if $width {width: $width};
    margin-right: 10px;
    padding-right: 20px;
  }

  &::-webkit-scrollbar-track {
    @if $track-color {background-color: $track-color};
    border-radius: 4px;
    @if $m-top {margin-top: $m-top};
    @if $m-top {margin-bottom: $m-top};
  }

  &::-webkit-scrollbar-thumb {
    background-color: var.$orange;
    height: 10px;
    border-radius: 4px;
  }
}


/* 11. controls hover element */
@mixin bg-hover-element($width: false, $height: false) {
  @include pseudo($position: absolute);
  @if $width {width: $width};
  @if $height {height: $height};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color:  var.$hover_main;
  border-radius: var.$radius_s;
  transition: opacity var.$transitionGlobal;
  opacity: 0;
}

/* 11. focus visible/hover outline for accessibility */
@mixin focus-visible-custom($md: false, $color:false) {
  @if($md == true) {
    box-shadow: 0 0 0 2px white, 0 0 0 4px $color;
  } @else {
    box-shadow: 0 0 0 2px $color;
  }
}

/* 12. default browsers outline for accessibility */
@mixin focus-visible-default {
  outline: auto;
  span {
    background-color: transparent;
  }
}






