
@use '../../../assets/sass/mixins';
@use '../../../assets/sass/common-elements/buttons';

.qrWrapper div[class*='loader']{
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  figure {
    max-width: 200px;
    padding: 10px;
  }

}


/* btn is visually in a share dialog component */
.share__btn {
  position: absolute;
  @include mixins.adaptiv-value('bottom', -44, -54, 1);
  width: calc(50% - 7px);
  right: 0;
  z-index: 5;

  @extend .btn__outline;
  @extend .icon;
  column-gap: 6px;
  justify-content: center;
  padding: 0 10px;
  @include mixins.adaptiv-value('border-radius', 8, 16, 1);
  @include mixins.adaptiv-value('padding-top', 9, 14, 1);
  @include mixins.adaptiv-value('padding-bottom', 9, 14, 1);
  font-size: 1rem;
}

@include mixins.max-width(350px) {
  .share__btn {
    width: 100%;
    bottom: -123px;
  }
}
