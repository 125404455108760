@use '../../../assets/sass/variables.scss' as var;
@use "../../../assets/sass/mixins";


.users {
  &__active {
    .users__name:not(.users__name__active), .users__btn {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 19px 0;
    user-select: none;
    &:not(:first-of-type) {
      border-top: 1px solid var.$white_dark;
    }
  }
  &__name {
    font-family: Gilroy, sans-serif;
    font-weight: 600;
    color: var.$black;
    transition: var.$transitionGlobal;
  }
  &__btn {
    font-family: Gilroy, sans-serif;
    background: transparent;
    font-weight: 400;
    font-size: 1.125rem;
    &__active {
      transition: inherit;
      color: var.$gray_text-light;
    }
  }
}

.choices {
  padding-bottom: 14px;
  &__text {
    font-weight: 400;
    text-align: center;
    color: var.$gray_dark;
    margin-bottom: 18px;
    user-select: none;
  }
  &__row {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    justify-items: center;
    &:before {
      @include mixins.divider(100%, 1px, false, false, var.$white_dark);
      position: static;
      order: 2;
    }
  }

  button {
    padding: 7px clamp(0.438rem, 3vw - 1rem, 0.688rem); // 11px
    text-align: center;
    flex: 1;
    background: transparent;
    font-size: 1rem;
    font-weight: 500;
    @include mixins.min-width(var.$tablet-res) {
      max-width: max-content;
      border-radius: var.$radius_s;
    }
    &:first-of-type {
      @include mixins.hover-supported {
        background-color: var.$hover_main;
      }
    }
    &:last-of-type {
      order: 3;
      @include mixins.hover-supported {
        background-color: var.$warningHover;
      }
    }
  }

}
