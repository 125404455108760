@use '../../../assets/sass/variables.scss' as *;
@use '../../../assets/sass/mixins' as mixin;
@use '../../../assets/sass/common-elements/buttons';

.premium {
  @include mixin.min-width($tablet-res) {
    margin-left: 255px;
  }
  div[class*='page_top'] {
    @include mixin.min-width($tablet-res) {
      margin-left: 30px;
    }
  }
  div[class*="MuiFormGroup-root"] {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    justify-content: center;
    grid-gap: 36px;
		@media (max-width: 1199px) {
			grid-template-columns: repeat(auto-fit, minmax(335px, 335px));

		}
  }

  // commot styles
  .choice {
    border-radius: $radius_s;
    padding: 25px 24px;
    label {
      padding: 0 !important;
      margin: 0 !important;
      grid-template-columns: 1fr !important;

      & > span {
        display: block;
        width: max-content;
        padding: 0 !important;
        margin: 0 0 15px !important;
      }
      & > span:nth-child(2) {
        font: 700 2rem/1 'Gilroy', sans-serif !important;
        margin: 0 0 15px !important;
      }
    }
    p {
      font-weight: 400;
      & + p {
        margin-top: 4px;
      }
    }
    mark {
      display: inline-block;
      border-radius: 6px;
      padding: 8px 16px;
      margin-top: 20px;
      margin-bottom: 28px;
      font-weight: 400;
    }
    strong {
      font: 700 2rem/1 Gilroy, sans-serif;
    }
    button {
      display: block;
      width: 100%;
      font: 600 1.25rem/1 'Montserrat', sans-serif;
      text-transform: uppercase;
      padding: 0 20px;
      @include mixin.adaptiv-value('padding-top', 17, 22, 1);
      @include mixin.adaptiv-value('padding-bottom', 17, 22, 1);
      margin-top: 20px;
      text-align: center;
      border-radius: $radius_s;
    }
  }
  // end commot styles

  &__year {
    @extend %premium-bg;
    label {
      color: $white !important;
      svg {
        fill: $white !important;
      }
    }
    p {
      color: $white;
    }
    mark {
      background: rgba($white, 0.3);
      color: $white;
    }
    button {
      background-color: $white;
      span {
        @extend %premium-color;
      }
    }
  }

  &__month {
    @extend %premium-bg--lite;
    box-shadow:  0 0 0 3px $violet inset;
    label span:nth-child(2) {
      @extend %premium-color;
      svg {
        fill: $violet !important;
      }
    }
    p {
      color: $black;
    }
    mark {
      background: radial-gradient(
          96.23% 85.68% at 14.33% 14.32%,
          rgba(206, 31, 246, 0.34) 0%,
          rgba(87, 95, 238, 0.34) 100%
        ),
        #ffffff;
      color: $violet;
      border: 1px solid $violet;
    }
    button {
      @extend %premium-bg;
      color: $white;
      background-size: 100%;
      @include mixin.hover-supported {
        background-size: 200%;
      }
    }
  }

  &__free {
    border: 1px dashed $gray_text-light;
    label {
      color: $gray_dark !important;
    }
    p {
      color: $gray_dark_2;
      &:last-of-type {
        margin-bottom: 28px;
      }
    }
    strong {
      color: $gray_dark;
    }
  }
}

.badge__current__plan {
  font-size: 1.125rem;
  line-height: 1.3;
  font-weight: 600;
  color: $gray_dark_2;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  border: 1px solid $gray_text-light;
  border-radius: 16px;
}

.most__popular__badge {
  font-size: 0.875rem;
  font-weight: 600;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 28px;
  position: absolute;
  inset: 0 0 auto 0;
  border-radius: 16px 16px 0 0;
  background-image: radial-gradient(
                  96.23% 85.68% at 14.33% 14.32%,
                  #ce1ff6 0%,
                  #575fee 100%);
}

.subscription__tabs {
  max-width: 330px;
  width: 100%;
  margin: 20px auto;
}