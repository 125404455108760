@use '../../../assets/sass/variables' as var;
@use '../../../assets/sass/mixins';

// delete after QA tests
.soon {
  display: flex;
  pointer-events: none;
  user-select: none;
  span:not(:last-of-type), div {
    opacity: .4;
  }
}
/* ===== */

.sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
  &__navigation {
    flex: 1 1 auto;
    margin-bottom: 35px !important;
  }
  &__list {
    @include mixins.d-flex($direction: column);
  }
  &__item {
    display: block !important;
    > a:first-of-type {
      //position: relative;
      font-size: clamp(1rem, 4.2vw - 1rem, 1.125rem); // 16px - 18px
      line-height: 1;
      border-radius: 16px;
      @include mixins.min-width(var.$tablet-res) {
        padding: 12px 20px 12px 23px;
        column-gap: 14px;
      }
      @include mixins.hover-supported {
        color: var.$orange;
        background-color: rgba(255, 255, 255, 0.08);
        .sidebar__icon {
          filter: contrast(1);
        }
      }
      &.active {
        color: var.$orange;
        background-color: rgba(255, 255, 255, 0.08);
        .sidebar__icon {
          filter: contrast(1);
        }
      }
    }
    &.has_inner {
      @include mixins.min-width(var.$tablet-res) {
        position: relative;
      }
      &:hover .inner__list {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
        transform: translateX(0);
      }
    }
  }
  .sidebar__btnWrapper {
    @include mixins.min-width(var.$tablet-res) {
      padding: 0 20px;
    }
  }
  &__btnPremium {
    margin-bottom: clamp(2.5rem, min(4vw + 1rem, 4vh + 1rem), 3.125rem);
  }

  /* sub menu */
  .inner {
    &__list {
      position: relative;
      @include mixins.max-width(var.$mobile-res) {
        position: relative;
        padding: 0 0 0 34px;
      }
      &::after {
        @include mixins.divider(
                $height: 100%,
                $width: 1px,
                $color: var.$white_dark,
                $left: 12px
        );
      }

    }
    &__item {
      @include mixins.max-width(var.$mobile-res) {
        a {
          display: block;
          padding: 14px 0;
        }
      }
      a.active  {
        color: var.$orange;
      }
      &:first-child {
        @include mixins.max-width(var.$mobile-res) {
          margin-top: 15px;
        }
      }
    }
  }
  svg {
    display: block;
  }
  &__icon {
    filter: contrast(0) brightness(140%);
    transition: .3s;
  }

  // min width 768px
  @include mixins.min-width(var.$tablet-res) {
    max-width: 255px;
    padding: clamp(15px, 3.5vh, 20px) 0 clamp(15px, 3.5vh, 30px);
    overflow-y: auto;
    &__list {
      grid-row-gap: 12px;
      padding-inline: 12px;
    }
    a:not(.active, .sidebar__btnPremium) {
      color: var.$gray_text-light;
    }
    .inner {
      &__list {
        margin: 10px 0 0 35px;
        padding-inline: 8px 12px;
      }
      &__list:after {
        left: 0;
        background-color: var.$gray_dark;
      }
      &__item:not(:last-of-type) a {
        margin-bottom: 2px;
      }
      &__item a{
        display: block;
        padding: 12px 14px;
        border-radius: 12px;
        line-height: 1;
        @include mixins.hover-supported {
          color: var.$orange;
          background-color: rgba(255, 255, 255, 0.08);
        }
        &.active {
          color: var.$orange;
          background-color: rgba(255, 255, 255, 0.08);
        }
      }
    }
  }

  // max width 767.9px
  @include mixins.max-width(var.$mobile-res) {
    padding: 20px 24px 25px;
    overflow-y: auto;
    &__list {
      grid-row-gap: 17px;
      a:not(.active) {
        color: var.$black;
      }
    }
    &__item {
      padding: 0 4px;
    }
  }

  .sidebar__message {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    color: var.$gray_text-light;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-inline: 12px;
    margin-bottom: 50px;
    padding-block: 12px;
    padding-inline: 16px;
    border: 1px solid var.$gray_dark;
    border-radius: 12px;

    .sidebar__message__title {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .sidebar__message__link {
      color: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 32px;
      border: 1px solid var.$gray_dark_2;
      border-radius: 8px;

      @include mixins.max-width(var.$mobile-res) {
        color: var.$black;
      }
    }

    @include mixins.max-width(var.$mobile-res) {
      color: var.$black;
    }
  }
}

/* sidebar bottom  */
.controls {
  @include mixins.d-flex(space-between, center);
  position: relative;
  @include mixins.adaptiv-value("padding-left", 26, 6, 1);
  @include mixins.adaptiv-value("padding-right", 26, 6, 1);
  &::after {
    @include mixins.divider(
            $height: 100%,
            $width: 1px,
            $left: 50%,
    );
  }
  button {
    &.active {
      color: var.$orange;
      .sidebar__icon {
        filter: contrast(1);
      }
    }
    @include mixins.hover-supported {
      color: var.$orange;
      .sidebar__icon {
        filter: contrast(1);
      }
    }
  }

  // min width 768px
  @include mixins.min-width(var.$tablet-res) {
    &::after {
      background-color: var.$gray_dark;
    }
    & > * {
      font-size: mixins.rem(14);
    }
  }

  // max width 767.9px
  @include mixins.max-width(var.$mobile-res) {
    &::after {
      background-color: var.$white_dark;
    }
    & > * {
      font-size: 1rem;
    }
  }
}
