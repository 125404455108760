@use '../../../../assets/sass/variables' as *;
@use '../../../../assets/sass/mixins';
@use '../../../../assets/sass/common-elements/buttons';
@use '../../../../assets/sass/common-elements/cards';

.share {
  user-select: none;
  &__wrapper {
    @include mixins.min-width($tablet-res) {
     padding: 0 96px;
    }
  }
  &__title {
    max-width: 335px;
    width: 100%;
    padding: 15px 30px 0 30px !important;
    display: grid;
    grid-template-columns: 24px 1fr 24px;
    column-gap: 5px;

    p {
      grid-column: 2/3;
      font-size: 1.375rem;
    }
    button {
      grid-column: 3/-1;
    }
  }

  &__close {
    background: transparent;
  }

  &__code {
    width: 205px;
    height: 205px;
    // background: rgb(117, 108, 108);
    margin: 0 auto 20px;
  }

  &__content {
    max-width: 335px;
    width: 100%;
    padding: 15px 30px 0 30px !important;
    @media (max-width: 21.875em) {
      padding: 15px 15px 0 15px !important;
    }
    overflow: unset !important;
    div[class*="MuiBox-root"] {
      position: relative;
    }
    div[class*="MuiBox-root"] > div ,
    div[class*="MuiBox-root"]  div
    [class*="react-swipeable-view-container"] > div {
      overflow: unset !important;
    }
  }

  &__link {
    word-break: break-all;
  }

  &__row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
    align-items: center;
    @media (max-width: 21.875em) {
      grid-template-columns: 1fr;
      row-gap: 15px;
    }
  }

  &__btn {
    @extend .btn__outline;
    @extend .icon;
    column-gap: 6px;
    justify-content: center;
    padding: 0 10px;
    @include mixins.adaptiv-value('border-radius', 8, 16, 1);
    @include mixins.adaptiv-value('padding-top', 9, 14, 1);
    @include mixins.adaptiv-value('padding-bottom', 9, 14, 1);
    font-size: 1rem;
  }
}

.border_top {
  @include mixins.adaptiv-value('padding-top', 30, 24, 1);
  border-top: 1px solid $white_dark;
  @include mixins.min-width($tablet-res) {
    margin-left: -40px;
    margin-right: -40px;
  }
}

.carousel {
  &__slide {
    padding: 10px 20px 0px;
    margin-bottom: 20px;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
    margin-bottom: 0;
    cursor: pointer;
    box-shadow: 0px 6px 20px -2px rgba(88, 88, 88, 0.16),
                0px 5px 12px -2px rgba(88, 88, 88, 0.1);
  }

  &__code {
    width: 140px;
    height: 140px;
    // margin-bottom: 20px;
    canvas {
      width: 100%;
      height: 100%;
    }
  }

  &__pagination {
    justify-content: center !important;
    margin-bottom: 12px;

    div {
      div {
        background-color: $gray_text-light;
        transition: width 300ms ease;
        border-radius: 5px;
      }
      div[class*='dotActive'] {
        background-color: $orange;
        width: 13px;
        height: 6px;
      }
    }
  }
  &__navArrow {
    @include mixins.min-width($tablet-res) {
      position: absolute !important;
      bottom: 50%;
      width: 38px;
      height: 38px;
      border: 1px solid transparent !important;
      min-width: unset !important;
      border-radius: 50%!important;
      background-color: $white !important;
      box-shadow:
              0 2px 10px rgba(88, 88, 88, 0.1),
              0 4px 6px -2px rgba(88, 88, 88, 0.1);
      &:after {
        @include mixins.chevron();
      }
      &[class*='Mui-disabled']:after {
        filter: contrast(0) brightness(140%);
      }
      &:first-of-type {
        left: -40px;
        &:after {
          transform: translate(-2px, 2px) rotate(-180deg);
        }
      }
      &:last-of-type {
        right: -40px;
        &:after {
          transform: translate(2px, -2px);
        }
      }
      &:not(&[class*='Mui-disabled']) {
        @include mixins.hover-supported {
          border-color: $orange_light_3 !important;
        }
      }
      svg {
        display: none;
      }
    }
  }
}

.export_block {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 5px;
}